<template>
	<div>
		<div id="header">
			<div class="nav">
				<div class="logo">
					<img src="../assets/img/logo1.png" alt />
					<div class="icon"></div>
					<img src="../assets/img/logo2.png" alt />
					<div class="icon"></div>
					<span style="display:inline-block;height:64px;line-height:64px">物联网云平台</span>
				</div>
				<div class="right">
					<ul class="right-nav">
						<li class="right-nav-item">
							<a @click.prevent="push('/', '首页')">首页</a>
						</li>
						<li class="right-nav-item drop-li" style="width:90px">
							<div class="drop-down">
								产品
								<img src="../assets/img/arrow_down_w.png" alt />
							</div>
							<div style="height:8px;width:100%"></div>
							<div class="drop-box">
								<div class="drop-tr"></div>
								<div class="drop-content">
									<ul class="drop-list">
										<li class="drop-item" @click="push('/iot', '物联网全连接云平台')">物联网全连接云平台</li>
										<li class="drop-item" @click="push('/router', '智能工业路由器')">智能工业路由器</li>
										<li class="drop-item" @click="push('/dataVisualization', '数据可视化')">数据可视化</li>
									</ul>
								</div>
							</div>
						</li>
						<li class="right-nav-item">
							<a href="javascript:;" @click="push('https://help.senthink.com', '文档中心', true)">文档中心</a>
						</li>
						<li class="right-nav-item">
							<a @click.prevent="push('/eCooperation', '生态合作')" href="javascript:;">生态合作</a>
						</li>
						<li class="right-nav-item">
							<a @click.prevent="push('/about', '关于我们')" href="javascript:;">关于我们</a>
						</li>
					</ul>
					<div class="icon"></div>
					<a class="try-btn trial" href="javascript:;" @click="push('https://account.xiot.senthink.com/register', '立即注册', true)">立即注册</a>
					<button class="try-btn hide console" @click="Login.jumpTo('/console', '控制台')">控制台</button>
					<div id="login" class="login hide">
						<a @click.prevent="Login.toLogin()">登录</a>
					</div>
					<div class="user hide" id="user">
						<div class="user-li">
							<div class="username-box">
								<span id="userName">11</span>
								<img src="../assets/img/arrow_down_w.png" alt />
							</div>
							<div style="height:8px;width:100%"></div>
							<div class="user-content">
								<div class="drop-tr"></div>
								<div class="logout" @click="Login.handleLogout()">
									<a href="javascript:;">退出登录</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Login from '../assets/js/login'
export default {
	data() {
		return {
			Login
		}
	},
	mounted() {
		this.Login.refreshState()
	},
	methods: {
		showModal() {
			this.$emit('showModal')
		},
		push (url, name, extra=false) {
			if (window?.location?.pathname === url) {
				return;
			}
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '导航', url]);
			}
			if (extra) {
				window.location.href = url;
			} else {
				this.$router.push(url);
			}
		}
	}
}
</script>
<style lang="less" scoped>
#header {
	display: flex;
	width: 100%;
	height: 64px;
	background: rgba(22, 23, 27, 0.7);
	min-width: 1280px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1;

	.nav {
		opacity: 1;
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo {
			display: flex;
			color: @font-color;
			align-items: center;

			img {
				height: 26px;
			}
		}

		.right {
			display: flex;
			float: right;
		}

		.right-nav {
			display: flex;
			flex: 1;

			.drop-li {
				cursor: pointer;
			}

			.drop-li:hover .drop-box {
				display: block;
			}

			.drop-li:hover img {
				transform: rotate(180deg);
			}

			.drop-down {
				width: 90px;
				height: 64px;

				img {
					transition: all 0.2s;
					top: 25px;
					margin-left: 4px;
					margin-bottom: 1px;
					vertical-align: middle;
					cursor: pointer;
				}
			}

			.drop-box {
				position: absolute;
				left: 50%;
				margin-left: -82px;
				width: 164px;
				height: 124px;
				display: none;

				.drop-tr {
					width: 0;
					height: 0;
					border: 4px solid transparent;
					border-bottom-color: @h1-color;
					border-top-width: 0;
					margin: 0 auto;
				}

				.drop-content {
					height: 120px;
					width: 164px;
					background-color: @h1-color;
					border-radius: 4px;
					padding: 21px 0 0 22px;
					box-sizing: border-box;

					.drop-list {
						color: @font-color;
						font-size: 14px;

						.drop-item {
							width: 100%;
							height: 19px;
							margin-bottom: 12px;
							line-height: 19px;
							text-align: left;
							cursor: pointer;

							&:hover {
								opacity: 0.6;
							}
						}
					}
				}
			}

			.right-nav-item {
				position: relative;
				width: 100px;
				height: 64px;
				line-height: 64px;

				a {
					cursor: pointer;
				}
			}

			.right-nav-item:first-child {
				width: 72px;
			}
		}

		.icon {
			align-self: center;
			width: 1px;
			height: 20px;
			margin: 0 15px;
			background-color: rgba(256, 256, 256, 0.6);
		}

		.try-btn {
			width: 100px;
			height: 32px;
			margin-right: 22px;
			background: @main-color;
			border-radius: 16px;
			font-size: 14px;
			border: none;
			color: @font-color;
			line-height: 32px;
			align-self: center;
			cursor: pointer;
			transition: 0.2s all linear;

			&:hover {
				color: @main-color;
				background: @font-color;
			}
		}

		.login {
			align-self: center;
			white-space: nowrap;
			margin-right: 22px;
			height: 64px;
			line-height: 64px;

			a {
				cursor: pointer;
			}
		}

		.user-li {
			align-self: center;
			white-space: nowrap;
			margin-right: 22px;
			height: 64px;
			width: 100%;
			line-height: 64px;
			position: relative;
			cursor: pointer;
			font-size: 14px;
			color: #ffffff;

			&:hover {
				.user-content {
					display: block;
				}

				img {
					transform: rotate(180deg);
				}
			}

			.username-box {
				width: 100%;

				img {
					transition: all 0.2s;
					top: 25px;
					margin-left: 4px;
					margin-bottom: 1px;
					vertical-align: middle;
					cursor: pointer;
				}
			}

			.user-content {
				position: absolute;
				left: 50%;
				margin-left: -50px;
				display: none;

				.drop-tr {
					width: 0;
					height: 0;
					border: 4px solid transparent;
					border-bottom-color: @h1-color;
					border-top-width: 0;
					margin: 0 auto;
				}

				.logout {
					height: 50px;
					line-height: 50px;
					width: 100px;
					background-color: @h1-color;
					border-radius: 4px;
					box-sizing: border-box;
					margin: 0 auto;
				}
			}
		}
	}
}
</style>