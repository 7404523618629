<template>
	<div>
		<sub-title-2 class="section_1-title">强大、安全、稳定、灵活</sub-title-2>
		<section class="section section_1">
			<div class="list">
				<div class="item icon_hover">
					<div class="icon_title icon_1_1"></div>
					<div class="title">稳接入</div>
					<div>提供稳定的接入服务，适配多种网络和多种接入协议</div>
				</div>
				<div class="item icon_hover">
					<div class="icon_title icon_1_2"></div>
					<div class="title">高可用</div>
					<div>具有海量设备连接能力和并发能力，微服务架构，集群部署，支持弹性水平拓展</div>
				</div>
				<div class="item icon_hover">
					<div class="icon_title icon_1_3"></div>
					<div class="title">安全性</div>
					<div>设备认证与权限管理，保障设备安全与唯一性；传输加密保障数据安全传输；关键业务数据加密,保障数据不被篡改</div>
				</div>
			</div>
			<div class="list">
				<div class="item icon_hover">
					<div class="icon_title icon_1_4"></div>
					<div class="title">低成本</div>
					<div>多种低成本远程运维手段，降低设备出货后人力运维成本</div>
				</div>
				<div class="item icon_hover">
					<div class="icon_title icon_1_5"></div>
					<div class="title">多承载</div>
					<div>平台提供丰富的API接口，满足应用各种开发场景</div>
				</div>
				<div class="item icon_hover">
					<div class="icon_title icon_1_6"></div>
					<div class="title">定制化</div>
					<div>架构灵活，既可以公有部署，降低系统运维成本，也可以快速私有定制部署，可适合不同客户的需求</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
import SubTitle2 from './SubTitle2.vue';
export default {
	components: { SubTitle2 },
	mounted() {
		this.init()
	},
	methods: {
		init() {
			const list = document.getElementsByClassName('icon_hover');
			for (let i = 0; i < list.length; i++) {
				let count = 21;
				let element = list[i];
				let ing = false;
				element.onmouseover = function () {
					const _this = this;
					ing = 'out';
					let over = setInterval(function () {
						count--;
						if (count > 0 && ing === 'out') {
							_this.getElementsByClassName('icon_title')[0].style.backgroundPositionY = count * 92 + 'px';
						} else {
							clearInterval(over);
						}
					}, 41);
				}
				element.onmouseout = function () {
					const _this = this;
					ing = 'over';
					let over = setInterval(function () {
						count++;
						if (count < 21 && ing === 'over') {
							_this.getElementsByClassName('icon_title')[0].style.backgroundPositionY = count * 92 + 'px';
						} else {
							clearInterval(over);
						}
					}, 41);
				}
			}
		}
	}
}
</script>
<style lang="less" scoped>
.section_1-title {
	margin: 66px auto 70px;
}
.section_1 {
	.list {
		display: inline-flex;
		justify-content: center;
	}
	.list:first-of-type {
		margin-bottom: 30px;
	}
	.list .item {
		width: 300px;
		height: 340px;
		background: #ffffff;
		border: 1px solid #f3f6fa;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.list .item:hover {
		box-shadow: 0px 6px 20px 0px rgba(2, 109, 255, 0.1);
	}
	.list .item + .item {
		margin-left: 76px;
	}
	.list .item .icon_title {
		width: 92px;
		height: 92px;
		background-position-y: 1840px;
		margin: 40px auto 38px;
	}
	.list .item .title {
		font-size: 20px;
		line-height: 32px;
		color: #333333;
		margin-bottom: 38px;
	}
	.list .item div:last-child {
		width: 224px;
		font-size: 14px;
		color: #999999;
	}
}
</style>