<template>
  <div>
    <div class="title-part">
      <div class="title-part-bg">
        <div class="title">关于我们</div>
        <div class="desc">
          利尔达科技集团旗下全资子公司，专业从事物联网系统研发，专
          <br />注于设备管理智能化和物联网应用系统开发。
        </div>
      </div>
    </div>
    <m-sub-title-2 class="sub-title2">公司介绍</m-sub-title-2>
    <div class="content">
      <div
        class="about"
      >依托利尔达强大的智能硬件优势，整合资源，拥有严谨规范的项目立项、研发、测试运作流程，完备的系统部署运维体系。贤芯科技致力于为用户提供整套完善稳健的物联网应用系统。</div>
      <div class="list">
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-user-settings-line"></i>
            </div>
            <div class="list-item-title-text">项目管理组</div>
          </div>
          <div
            class="list-item-desc"
          >基于CMMI3过程能力的项目流程，多年大型物联网项目实施管理经验，能有效组织、领导、协调相关干系人各项工作，严格履行合同要求，为完成既定的质量、进度等目标而努力的项目管理团队。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-chat-check-line"></i>
            </div>
            <div class="list-item-title-text">产品组</div>
          </div>
          <div
            class="list-item-desc"
          >深耕于物联网用、云、边、管、端各领域数年，为客户提供一站式IoT解决方案，涵盖：市场调研分析、需求沟通梳理、功能原型设计、产品全生命周期管理等。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-pencil-ruler-2-line"></i>
            </div>
            <div class="list-item-title-text">设计组</div>
          </div>
          <div
            class="list-item-desc"
          >精通PS、AI、Sketch、AE等设计软件，兼具平面、UI、动画等设计领域，具备扎实的设计基础，多年设计经验及团队协作能力，能快速响应客户需求。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-terminal-box-line"></i>
            </div>
            <div class="list-item-title-text">研发组（前端、后端、移动端）</div>
          </div>
          <div
            class="list-item-desc"
          >拥有完善、成熟的管理和开发流程，集架构思维、设计执行、服务意识与一体，汇聚物联网领域的技术精英，对物联网行业的发展和趋势有着深刻的认识和理解，有着共同目标默契协作的研发团队。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-user-search-line"></i>
            </div>
            <div class="list-item-title-text">测试组</div>
          </div>
          <div class="list-item-desc">保障系统业务高质量交付，疑难问题高效率解决，用户体验高品质提升，化身千万用户，致力于成为测试服务领域里最被信任的探索者。</div>
        </div>
        <div class="list-item no-border">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-file-cloud-line"></i>
            </div>
            <div class="list-item-title-text">运维组</div>
          </div>
          <div
            class="list-item-desc"
          >拥有丰富的Linux系统生态运维经验，同时专注于云原生，容器云，以及各大云平台SaaS云产品运维。提供项目上线，容量规划，运行保障，7*24响应等等运维服务。</div>
        </div>
      </div>
      <m-sub-title-2 class="sub-title2">荣誉资质</m-sub-title-2>
      <div class="list-credentials">
        <div class="list-credentials-item" v-for="( item, index) in list" :key="index">
          <div class="dot"></div>
          <div class="item-text">{{ item }}</div>
        </div>
      </div>
      <img class="credentials-img" src="../../assets/img/ryzs.png" alt />
      <m-sub-title-2 class="sub-title2">合作伙伴</m-sub-title-2>
    </div>
    <van-swipe class="my-swipe" indicator-color="#2E6BF6" :autoplay="5000">
      <van-swipe-item>
        <div class="swipe-img-part">
          <img src="../../assets/img/hzhb1.jpg" alt />
          <img src="../../assets/img/hzhb2.png" alt />
          <img src="../../assets/img/hzhb3.png" alt />
          <img src="../../assets/img/hzhb4.png" alt />
          <img src="../../assets/img/hzhb5.png" alt />
          <img src="../../assets/img/hzhb6.png" alt />
          <img src="../../assets/img/hzhb7.png" alt />
          <img src="../../assets/img/hzhb8.png" alt />
          <img src="../../assets/img/hzhb9.png" alt />
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipe-img-part">
          <img src="../../assets/img/hzhb10.png" alt />
          <img src="../../assets/img/hzhb11.png" alt />
          <img src="../../assets/img/hzhb12.png" alt />
          <img src="../../assets/img/hzhb13.png" alt />
          <img src="../../assets/img/hzhb14.png" alt />
          <img src="../../assets/img/hzhb15.png" alt />
          <img src="../../assets/img/hzhb16.png" alt />
        </div>
      </van-swipe-item>
    </van-swipe>
    <m-sub-title-2 class="sub-title2">联系我们</m-sub-title-2>
    <div class="contact-part">
      <img class="QR" src="../../assets/img/ewm.png" alt />
      <div class="QR-text">贤芯官方公众号</div>
      <div class="contact">
        <div class="city">
          <i class="ri-map-pin-2-fill"></i>
          <div class="city-name">浙江杭州</div>
        </div>
        <div class="contact-item">
          <div class="label">公司地址</div>
          <div class="item">杭州市文一西路1326号利尔达物联网科技园1号楼</div>
        </div>
        <div class="contact-item">
          <div class="label">公司邮箱</div>
          <div class="item">support@senthink.com</div>
        </div>
        <div class="contact-item">
          <div class="label">商务对接</div>
          <div class="item">王先生-18167172990</div>
        </div>
        <img class="map" src="../../assets/img/dt_m.png" alt />
      </div>
    </div>
    <m-bottom-banner>
      <template #title>立刻成为生态伙伴</template>
      <template #desc>期待与您携手共创万物互联时代！</template>
    </m-bottom-banner>
  </div>
</template>

<script>
import MBottomBanner from '../../components/mobile/MBottomBanner.vue'
import MSubTitle2 from '../../components/mobile/MSubTitle2.vue'
export default {
  components: { MSubTitle2, MBottomBanner },
  data() {
    return {
      list: ['杭州市高新技术企业', '杭州市雏鹰计划企业', '软件企业双软企业证书', '杭州市人工智能学会会员', '杭州市余杭区科技企业研发中心', '数据资源开发协会理事单位', '浙江省科技型中小企业证书', '浙江省软件行业协会理事单位', 'CMMI 3级认证/ISO9001/ 27001认证']
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
}
.title-part {
  width: 100%;
  height: 260px;
  background: url("../../assets/img/banner_gywm_m.png");
  background-size: 100% 100%;
}
.title-part-bg {
  width: 100%;
  height: 100%;
  background: rgba(22, 23, 27, 0.5);
  padding-top: 145px;
  box-sizing: border-box;
  .title {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
  }
  .desc {
    margin-top: 10px;
    font-size: 11px;
    color: #ffffff;
    line-height: 17px;
  }
}
.about {
  width: 100%;
  font-size: 12px;
  color: #777f90;
  line-height: 20px;
  text-align: left;
}
.list {
  background-color: #fff;
}
.list-item {
  height: 130px;
  box-sizing: border-box;
  padding: 0 10px;
  text-align: left;
  padding-top: 15px;
  border-bottom: 1px solid #eaf0fe;
  .list-item-title {
    display: flex;
    align-items: center;
    .list-item-icon {
      width: 33px;
      height: 33px;
      border: 1px solid #2e6bf6;
      border-radius: 50%;
      line-height: 33px;
      text-align: center;
      font-size: 20px;
      color: #2e6bf6;
      margin-right: 10px;
    }
    .list-item-title-text {
      font-size: 13px;
      color: #0f1d2f;
      line-height: 18px;
    }
  }
  .list-item-desc {
    font-size: 11px;
    color: #777f90;
    line-height: 18px;
    margin-top: 8px;
    text-align: justify;
  }
}
.list-credentials-item {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eaf0fe;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #16171b;
  line-height: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  margin-bottom: 6px;
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #d5e1fd;
    margin-right: 10px;
  }
}
.credentials-img {
  width: 100%;
  margin-top: 5px;
}
.my-swipe {
  width: 100%;
  .van-swipe-item {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }
}
/deep/ .van-swipe__indicators {
  bottom: 0;
}
/deep/ .van-swipe__indicator {
  height: 4px;
  width: 4px;
  background: #d5e1fd;
}
.swipe-img-part {
  display: grid;
  width: 100%;
  margin-bottom: 14px;
  height: 100%;
  grid-template-columns: repeat(3, calc(33.33% - 4px));
  grid-template-rows: repeat(3, calc(33.33% - 4px));
  grid-gap: 6px;
  box-sizing: border-box;
  img {
    width: 100%;
    background: #ffffff;
    box-shadow: 2px 2px 5px 0px rgba(46, 107, 246, 0.14);
    border-radius: 2px;
  }
}
.contact-part {
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 20px;
  position: relative;
  .QR {
    width: 64px;
    height: 64px;
    background: #ffffff;
    box-shadow: 2px 2px 5px 0px rgba(46, 107, 246, 0.14);
    border-radius: 2px;
    position: absolute;
    top: 110px;
    right: 30px;
    z-index: 1;
  }
  .QR-text {
    font-size: 9px;
    color: #16171b;
    position: absolute;
    line-height: 12px;
    z-index: 1;
    top: 180px;
    right: 28px;
  }
}
.contact {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  padding-top: 17px;
  background: #fff;
  text-align: left;
  position: relative;
  .city {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    .ri-map-pin-2-fill {
      font-size: 20px;
      color: rgb(46, 107, 246);
    }
    .city-name {
      font-size: 13px;
      font-weight: bold;
      color: #16171b;
      line-height: 18px;
    }
  }
  .contact-item {
    margin-top: 15px;
    .label {
      font-size: 12px;
      color: #16171b;
      line-height: 16px;
    }
    .item {
      font-size: 11px;
      margin-top: 6px;
      color: #777f90;
      line-height: 15px;
    }
  }
  .map {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.no-border {
  border: none !important;
}
.border-right {
  border-right: 1px solid #eaf0fe;
}
.sub-title1 {
  text-align: left;
  display: flex;
  justify-content: left;
}
.sub-title2 {
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>