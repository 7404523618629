<template>
	<section>
		<div class="section section_5">
			<div>
				<sub-title-2 class="section_5-title">定位服务</sub-title-2>
				<div class="sub-title">
					<div>接入平台的智能设备可通过上报周围的基站，Wi-Fi信息来获取对应的地理位置信息</div>
				</div>
			</div>
			<div class="content">
				<div>
					<div class="box">
						<img
							src="../assets/img/dingwei_1.png"
							alt=""
						>
						<div class="name">基站定位</div>
					</div>
					<div class="box">
						<img
							src="../assets/img/dingwei_2.png"
							alt=""
						>
						<div class="name">Wi-Fi定位</div>
					</div>
					<div class="box">
						<img
							src="../assets/img/dingwei_3.png"
							alt=""
						>
						<div class="name">实时位置</div>
					</div>
				</div>
				<div>
					<div class="box">
						<img
							src="../assets/img/dingwei_4.png"
							alt=""
						>
						<div class="name">混合定位</div>
					</div>
					<div class="box">
						<img
							src="../assets/img/dingwei_5.png"
							alt=""
						>
						<div class="name">位置转换</div>
					</div>
					<div class="box">
						<img
							src="../assets/img/dingwei_6.png"
							alt=""
						>
						<div class="name">历史轨迹</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import SubTitle2 from './SubTitle2.vue'
export default {
	components: { SubTitle2 },

}
</script>
<style lang="less" scoped>
.section_5-title {
	margin-top: 60px;
  margin-bottom: 37px;
}
.section_5 {
	padding-bottom: 58px;
	text-align: center;
	background: #f9fbff;
	.sub-title {
		font-size: 16px;
		color: #999999;
		margin-bottom: 48px;
	}
	.sub-title > div {
		font-size: 16px;
		color: #999999;
	}
	.content {
		width: 1080px;
		height: 435px;
		margin: 0 auto;
		padding: 70px 91px 49px 155px;
		box-sizing: border-box;
		background: url("../assets/img/bg_ditu.png");
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		> div {
			display: flex;
			justify-content: space-between;
			.name {
				font-size: 20px;
				font-family: -apple-system,Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin-top: 29px;
			}
		}
	}
}
</style>