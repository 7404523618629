<template>
	<section class="section section_4">
		<div>
			<div class="nav">
				<ul>
					<li
						id="nav_1"
						class="section_4_nav"
					>
						<span>设备接入</span>
					</li>
					<li
						id="nav_2"
						class="section_4_nav"
					>
						<span>设备管理</span>
					</li>
					<li
						id="nav_3"
						class="section_4_nav"
					>
						<span>运维监控</span>
					</li>
					<li
						id="nav_4"
						class="section_4_nav"
					>
						<span>应用承载</span>
					</li>
					<li
						id="nav_5"
						class="section_4_nav"
					>
						<span>数据流转</span>
					</li>
				</ul>
			</div>
			<main class="content">
				<sub-title-2 class="section_4-title">平台功能</sub-title-2>
				<div class="sub-title">面向企业，打造专属</div>
				<div class="content nav_1">
					<div class="item no-right-bottom">
						<div class="icon icon_4_1_1"></div>
						<div class="title">多网络接入</div>
						<div>支持蜂窝、NB-IOT、WI-FI、有线等多网络接入，屏蔽物联网碎片化，实现设备快速接入。</div>
					</div>
					<div class="item only-top">
						<div class="icon icon_4_1_2"></div>
						<div class="title">多协议接入</div>
						<div>支持设备通过MQTT、TCP/透传、HTTP、UDP、CoAP、LwM2M等协议接入。</div>
					</div>
					<div class="item no-left-bottom">
						<div class="icon icon_4_1_3"></div>
						<div class="title">安全接入</div>
						<div>提供—型—密免注册、预注册和—机一密预注册等设备安全认证机制，防止设备非法接入。</div>
					</div>
					<div class="item no-right-top">
						<div class="icon icon_4_1_4"></div>
						<div class="title">云云接入</div>
						<div>支持第三方云平台接入，如阿里IoT、电信AEP、移动OneNet、亚马逊IoT等。</div>
					</div>
					<div class="item only-bottom">
						<div class="icon icon_4_1_5"></div>
						<div class="title">网关接入</div>
						<div>支持设备通过网关方式接入，网关与子设备拓扑架构的管理。</div>
					</div>
					<div class="item no-left-top">
						<div class="icon icon_4_1_6"></div>
						<div class="title">双向通信</div>
						<div>设备与云端双向通信，平台提供安全的传输通道，保障设备上报与指令下发稳定可靠。</div>
					</div>
				</div>
				<div class="content nav_2">
					<div class="item no-right-bottom">
						<div class="icon icon_4_2_1"></div>
						<div class="title">物模型</div>
						<div>提供物模型定义功能,可将设备属性、服务、事件使用标准的格式进行数字化表示。</div>
					</div>
					<div class="item only-top">
						<div class="icon icon_4_2_2"></div>
						<div class="title">数据解析</div>
						<div>将设备透传的非标准格式数据通过数据解析与平台标准格式进行转化。</div>
					</div>
					<div class="item no-left-bottom">
						<div class="icon icon_4_2_3"></div>
						<div class="title">设备生命周期</div>
						<div>设备生命周期管理，包含设备注册、设备更新、设备查询、设备禁用、设备删除。</div>
					</div>
					<div class="item no-right-top">
						<div class="icon icon_4_2_4"></div>
						<div class="title">在线状态监控</div>
						<div>平台实时监控设备的状态，包括在线、离线、未激活，实时获取状态变更通知。</div>
					</div>
					<div class="item only-bottom">
						<div class="icon icon_4_2_5"></div>
						<div class="title">在线调试</div>
						<div>提供在线调试功能，便于用户快速调试设备以及定位设备问题。</div>
					</div>
					<div class="item no-left-top">
						<div class="icon icon_4_2_6"></div>
						<div class="title">设备影子</div>
						<div>缓存设备最新状态与命令，避免网络不稳定导致无法实时控制设备，无需关心设备是否在线。</div>
					</div>
				</div>
				<div class="content nav_3">
					<div class="item no-right-bottom">
						<div class="icon icon_4_3_1"></div>
						<div class="title">OTA升级</div>
						<div>支持静态、动态、群组升级等多种策略设置,赋能设备远程升级。</div>
					</div>
					<div class="item only-top">
						<div class="icon icon_4_3_2"></div>
						<div class="title">日志分析</div>
						<div>设备和平台全链路日志记录，可通过多种维度快速检索分析。</div>
					</div>
					<div class="item no-left">
						<div class="icon icon_4_3_3"></div>
						<div class="title">远程配置</div>
						<div>可在不用重启设备或中断设备运行情况下，在线远程更新设备的系统参数、网络参数等配置信息。</div>
					</div>
					<div class="item no-right-top">
						<div class="icon icon_4_3_4"></div>
						<div class="title">实时监控</div>
						<div>平台提供在线设备数量、上下行消息数量、设备网络状态等指标数据的实时监控功能。</div>
					</div>
					<div class="item only-bottom">
						<div class="icon icon_4_3_5"></div>
						<div class="title">文件上传</div>
						<div>设备可将运行日志、配置信息等文件上传到平台，便于日志分析、故障定位以及设备数据备份。</div>
					</div>
					<div class="item no-left-top">
						<div class="icon icon_4_3_6"></div>
						<div class="title">告警通知</div>
						<div>基于规则引擎设置的告警规则，可触发短信、邮箱、钉钉、微信等实时通知。</div>
					</div>
				</div>
				<div class="content nav_4">
					<div class="item no-right">
						<div class="icon icon_4_4_1"></div>
						<div class="title">应用订阅</div>
						<div>应用可自由订阅设备的状态变更、设备消息上报、OTA结果等消息。</div>
					</div>
					<div class="item no-left-right">
						<div class="icon icon_4_4_2"></div>
						<div class="title">消息推送</div>
						<div>平台根据应用订阅推送类型将消息转发至各通信组件，如HTTP、RabbitMQ、MQTT等。</div>
					</div>
					<div class="item no-left">
						<div class="icon icon_4_4_3"></div>
						<div class="title">Open API</div>
						<div>开放平台API，满足各种应用开发需求。</div>
					</div>
				</div>
				<div class="content nav_5">
					<div class="item no-right-bottom">
						<div class="icon icon_4_5_1"></div>
						<div class="title">消息上报</div>
						<div>设备接入到物联网平台后，可通过指定的Topic或通道将数据上报到平台。</div>
					</div>
					<div class="item no-left-right">
						<div class="icon icon_4_5_2"></div>
						<div class="title">消息路由</div>
						<div>平台对数据路由灵活控制，将设备数据进行安全流转。</div>
					</div>
					<div class="item no-left">
						<div class="icon icon_4_5_3"></div>
						<div class="title">规则引擎</div>
						<div>使用SQL语句来对数据进行清洗处理，配置规则并将处理后的数据转发到其他服务或应用。</div>
					</div>
					<div class="item no-top">
						<div class="icon icon_4_5_4"></div>
						<div class="title">传输加密</div>
						<div>基于TLS、DTLS+加密协议，提供安全的传输通道。</div>
					</div>
				</div>
			</main>
		</div>
	</section>
</template>
<script>
import SubTitle2 from './SubTitle2.vue'
export default {
	components: { SubTitle2 },
	data() {
		return {
			items: ['nav_1', 'nav_2', 'nav_3', 'nav_4', 'nav_5'],
			active: 'nav_1',
		}
	},
	mounted() {
		this.changeClass()
		this.init4()
	},
	methods: {
		changeClass() {
			for (let j = 0; j < this.items.length; j++) {
				const nav = document.getElementById(this.items[j]);
				const content = document.getElementsByClassName(this.items[j])[0];
				if (!content) {
					break;
				}
				if (this.active === this.items[j]) {
					nav.className = nav.className.indexOf('active') > -1 ? nav.className : nav.className + ' active';
					content.style.display = 'flex';
				} else {
					nav.className = nav.className.indexOf('active') > -1 ? nav.className.replace(' active', '') : nav.className;
					content.style.display = 'none';
				}
			}
		},
		init4() {
			const list = document.getElementsByClassName('section_4_nav');
			for (let i = 0; i < list.length; i++) {
				list[i].onclick = () => {
					this.active = list[i].id;
					this.changeClass();
				}
			}

		}
	}
}
</script>
<style lang="less" scoped>
@keyframes in {
	0% {
		height: 30px;
	}
	50% {
		height: 0px;
	}
	100% {
		height: 30px;
	}
}
@keyframes out {
	from {
		height: 30px;
	}
	to {
		height: 0;
	}
}
.section {
	text-align: center;
	width: 100%;
}
.section .sub-title {
	font-size: 16px;
	color: #999999;
	margin-bottom: 76px;
}

.section .sub-title > div {
	font-size: 16px;
	color: #999999;
}
.section_4 {
	height: 860px;
	width: 100%;
}
.section_4-title {
	margin-left: 202px;
	margin-bottom: 23px;
	margin-top: 60px;
}
.section_4 > div {
	width: 1200px;
	height: 100%;
	display: inline-flex;
}
.section_4 > div .nav {
	width: 200px;
	height: 100%;
	z-index: 9;
	padding-top: 270px;
}
.section_4 > div .nav li {
	height: 54px;
	margin-bottom: 48px;
	padding: 10px 30px;
	position: relative;
	text-align: left;
	cursor: pointer;
}
.section_4 > div .nav li:before {
	content: "";
	width: 6px;
	height: 0;
	background: #fff;
	border-radius: 3px;
	position: absolute;
	left: 0;
	transition: height ease-in 1s;
}
.section_4 > div .nav li span {
	position: relative;
	font-size: 18px;
	color: #fff;
	transition: font-size ease-in 0.1s;
}
.section_4 > div .nav li:hover span {
	font-size: 22px;
}
.section_4 > div .nav li.active span {
	font-size: 22px;
}
.section_4 > div .nav li.active:after {
	content: "";
	width: 0;
	height: 0;
	border-color: #026dff;
	border-top: solid 23px transparent;
	border-left: solid 20px #026dff;
	/* 白色小三角形 */
	border-bottom: solid 23px transparent;
	position: absolute;
	right: -20px;
	top: 3px;
}
.section_4 > div > .content {
	text-align: left;
	width: 1000px;
	height: 100%;
	position: relative;
}
.section_4 > div > .content:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 860px;
	transform: translateX(-100%);
	background-image: url(../assets/img/section_4_nav_bg.png);
	background-size: contain;
}
.section_4 > div > .content header {
	font-size: 32px;
	line-height: 32px;
	margin-bottom: 30px;
	color: #333333;
	margin-left: 289px;
}
.section_4 > div > .content .sub-title {
	font-size: 16px;
	line-height: 16px;
	color: #999999;
	margin-left: 294px;
}
.section_4 > div > .content .content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-left: 50px;
	max-width: 1200px;
}
.section_4 > div > .content .content .item {
	padding-top: 39px;
	padding-left: 35px;
	padding-right: 41px;
	width: 310px;
	height: 260px;
	border: 1px solid #f3f6fa;
	text-align: left;
	box-sizing: border-box;
}
.section_4 > div > .content .content .item .icon {
	width: 60px;
	height: 60px;
	margin-bottom: 45px;
}
.section_4 > div > .content .content .item .title {
	font-size: 20px;
	height: 20px;
	color: #333333;
}
.section_4 > div > .content .content .item > div:last-of-type {
	margin-top: 20px;
	font-size: 14px;
	color: #999999;
}
.section_4 > div > .content .content .item:hover {
	box-shadow: 0 0 22px rgba(0, 59, 140, 0.1);
}
.box-shadow1 {
	box-shadow: 0px 0px 10px rgba(0, 59, 140, 0.1);
}

.no-left {
	/* .right-bottom,.right-top组合 */
	box-shadow: 10px 0 10px -10px rgba(0, 59, 140, 0.1),
		0 10px 10px -10px rgba(0, 59, 140, 0.1),
		0 -10px 10px -10px rgba(0, 59, 140, 0.1);
}

.no-right {
	/* .left-top，.left-bottom组合 */
	box-shadow: -10px -10px 10px -10px rgba(0, 59, 140, 0.1),
		-10px 10px 10px -10px rgba(0, 59, 140, 0.1);
}

.no-top {
	box-shadow: 0 10px 10px -10px rgba(0, 59, 140, 0.1),
		-10px 0 10px -10px rgba(0, 59, 140, 0.1),
		10px 0 10px -10px rgba(0, 59, 140, 0.1);
}

.no-left-top {
	/* .right-bottom,.right-top组合 */
	box-shadow: 10px 0 10px -10px rgba(0, 59, 140, 0.1),
		0 10px 10px -10px rgba(0, 59, 140, 0.1);
}

.no-left-bottom {
	box-shadow: 10px 0 10px -10px rgba(0, 59, 140, 0.1),
		0 -10px 10px -10px rgba(0, 59, 140, 0.1);
}

.no-left-right {
	box-shadow: 0 10px 10px -10px rgba(0, 59, 140, 0.1),
		0 -10px 10px -10px rgba(0, 59, 140, 0.1);
}
.no-right-top {
	/* .left-bottom,,right-bottom组合 */
	box-shadow: 0 10px 10px -10px rgba(0, 59, 140, 0.1),
		-10px 0px 10px -10px rgba(0, 59, 140, 0.1);
}
.no-right-bottom {
	/* .left-bottom,,right-bottom组合 */
	box-shadow: 0 -10px 10px -10px rgba(0, 59, 140, 0.1),
		-10px 0 10px -10px rgba(0, 59, 140, 0.1);
}
.only-top {
	box-shadow: 0px -10px 10px -10px rgba(0, 59, 140, 0.1);
}
.only-bottom {
	box-shadow: 0px 10px 10px -10px rgba(0, 59, 140, 0.1);
}
</style>