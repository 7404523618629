<template>
  <div class="x-m-header">
    <van-popup v-model="show" position="right" class="popup">
      <i class="ri-close-fill" @click="showPopup"></i>
      <div class="popup-content">
        <div class="menu">
          <div class="menu-item" @click="routerPush('/m_index', '首页')">首页</div>
          <div class="menu-item" @click="showListItem">
            <span>产品</span>
            <i class="ri-arrow-down-s-line" :class="{ 'ri-arrow-down-s-line-rotate': showList }"></i>
          </div>
          <div>
            <transition name="list">
              <div class="list" v-show="showList">
                <div class="list-item" @click="routerPush('/m_iot', '物联网全连接云平台')">物联网全连接云平台</div>
                <div class="list-item" @click="routerPush('/m_router', '智能工业路由器')">智能工业路由器</div>
                <div class="list-item" @click="routerPush('/m_dataVisualization', '数据可视化')">数据可视化</div>
              </div>
            </transition>
          </div>
          <a class="menu-item"  href="javascript:;" @click="push('https://help.senthink.com', '文档中心', true)" >文档中心</a>
          <div class="menu-item" @click="routerPush('/m_eCooperation', '生态合作')">生态合作</div>
          <div class="menu-item" @click="routerPush('/m_about', '关于我们')">关于我们</div>
        </div>
        <div class="login-box">
          <div id="mLogout" class="register-btn hide" @click.prevent="Login.handleLogout()">退出登录</div>
          <a href="javascript:;" @click="push('https://account.xiot.senthink.com/register', '注册', true)"  class="register-btn trial">注册</a>
          <div id="mLogin" @click.prevent="Login.toLogin()" class="login-btn hide">登录</div>
          <div class="login-btn hide console" @click.prevent="Login.jumpTo('/console')">控制台</div>
        </div>
      </div>
    </van-popup>
    <div class="logo-group">
      <img class="m-logo" src="../../assets/img/logo1.png" alt />
      <div class="icon"></div>
      <img class="m-logo2" src="../../assets/img/logo2.png" alt />
    </div>
    <i class="ri-menu-line" @click="showPopup"></i>
  </div>
</template>

<script>
import Login from '../../assets/js/login'
export default {
  data() {
    return {
      show: false,
      showList: false,
      Login
    }
  },
  methods: {
    showPopup() {
      this.show = !this.show
      this.$nextTick(() => {
        this.Login.refreshState()
      })
    },
    showListItem() {
      this.showList = !this.showList
    },
    routerPush(router, name) {
      this.showPopup()
      this.$router.push(router)
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '移动端-导航', router]);
			}
    },
		push (url, name, extra=false) {
			if (window?.location?.pathname === url) {
				return;
			}
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '移动端-导航', url]);
			}
			if (extra) {
				window.location.href = url;
			} else {
				this.$router.push(url);
			}
		}
  }
}
</script>

<style scoped lang="less">
.x-m-header {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: rgba(22, 23, 27, 0.7);
  padding: 0 15px;
}

.logo-group {
  display: flex;
  justify-content: space-between;
}

.m-logo {
  width: 58px;
  height: 17px;
}

.m-logo2 {
  width: 67px;
  height: 17px;
}

.icon {
  align-self: center;
  width: 1px;
  height: 15px;
  margin: 0 10px;
  background-color: rgba(256, 256, 256, 0.6);
}

.ri-menu-line {
  width: 17px;
  height: 17px;
  font-size: 17px;
  color: #fff;
}

.ri-close-fill {
  font-size: 22px;
}

.ri-arrow-down-s-line {
  font-size: 16px;
  line-height: 18px;
  float: right;
  transition: all 0.3s;
}

.ri-arrow-down-s-line-rotate {
  transform: rotate(180deg);
}

.popup {
  width: 260px;
  height: 100%;
  text-align: right;
  padding: 0 15px;
  padding-top: 14px;
  box-sizing: border-box;

  transform: tra;
}

.menu {
  margin-top: 28px;
  text-align: left;
  font-size: 13px;
  color: #0f1d2f;
  line-height: 18px;

  .menu-item {
    display: block;
    height: 18px;
    color: #0f1d2f;
    font-size: 13px;
    padding: 15px 0;
    border-bottom: 1px solid #eaf0fe;
  }
}

.list {
  transition: all linear 0.3s;
  font-size: 13px;
  color: #9b9daf;
  overflow: hidden;
  position: relative;

  .list-item {
    padding: 14px 0;
    padding-left: 26px;
    border-bottom: 1px solid #eaf0fe;
  }
}

.list-enter-active,
.list-leave-active {
  transition: "opacity" 0.5s ease;
  transition: "max-height" 0.5 s ease;
}

.list-enter,
.list-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  max-height: 0;
  opacity: 0;
}

.list-enter-to,
.list-leave {
  max-height: 138px;
}

.login-box {
  position: absolute;
  bottom: 83px;
  font-size: 14px;

  .register-btn {
    display: block;
    width: 230px;
    height: 38px;
    border-radius: 2px;
    color: #2e6bf6;
    text-align: center;
    line-height: 38px;
    border: 1px solid #2e6bf6;
    box-sizing: border-box;
    margin-bottom: 15px;
  }

  .login-btn {
    width: 230px;
    height: 38px;
    color: #ffffff;
    background: #2e6bf6;
    text-align: center;
    line-height: 38px;
    border-radius: 2px;
  }
}
</style>