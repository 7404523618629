<template>
  <div class="m-index">
    <div class="title-box">
      <div class="title">利尔达物联网全连接云平台</div>
      <div class="desc">
        为企业提供物联网全连接云平台、智能工业网关、数据可视化等
        专业物联网服务，助力客户快速搭建物联网应用。
      </div>
    </div>
    <div class="content">
      <m-sub-title1>
        <div class="sub-title-1">物联网全连接云平台</div>
      </m-sub-title1>
      <div
        class="item-desc"
      >为设备提供安全可靠的连接管理能力，整合物联网产业链上下游，向下连接智能设备，向上承接物联网应用，形成端到云完整链路物联网解决方案，赋能企业各类IoT场景接入。</div>
      <div class="iot-img-group">
        <img class="iot-img" src="../assets/img/sy_img_iot.png" alt />
        <div class="iot-item-part">
          <div class="iot-item">
            <div class="iot-icon-item">
              <i class="ri-link icon iot-icon"></i>
            </div>
            <span class="iot-text">海量碎片化连接</span>
          </div>
          <div class="iot-item">
            <div class="iot-icon-item">
              <i class="ri-shape-line icon iot-icon"></i>
            </div>
            <span class="iot-text">物模型规则引擎</span>
          </div>
          <div class="iot-item">
            <div class="iot-icon-item">
              <i class="ri-router-line icon iot-icon"></i>
            </div>
            <span class="iot-text">数据路由分发</span>
          </div>
        </div>
      </div>
      <div class="detail" @click="push('/m_iot', '物联网全连接云平台')">
        <div class="detail-text">了解详情</div>
        <img src="../assets/img/arrow_right_s.png" alt />
      </div>
      <m-sub-title1>
        <div class="sub-title-1">智能工业路由器</div>
      </m-sub-title1>
      <div
        class="item-desc"
      >专为非智能设备打造的智能工业路由器，支持RS485、RS232和以太网设备接入，具有工业协议解析、数据清洗和数据转发功能，快速实现非IoT设备数据采集和指令控制。</div>
      <div class="detail" @click="push('/m_router', '智能工业路由器')" style="margin-top:15px">
        <div class="detail-text">了解详情</div>
        <img src="../assets/img/arrow_right_s.png" />
      </div>
      <div class="router-part">
        <div class="router-item">
          <div class="router-item-icon-box">
            <i class="ri-numbers-line ri-icon"></i>
          </div>
          <div class="router-item-text">
            <div class="router-item-subtitle">高效</div>
            <div class="router-item-subdesc">JS脚本快速开发解析协议，比传统开发减少80%时间</div>
          </div>
        </div>
        <div class="router-item">
          <div class="router-item-icon-box">
            <i class="ri-vip-crown-line ri-icon"></i>
          </div>
          <div class="router-item-text">
            <div class="router-item-subtitle">强大</div>
            <div class="router-item-subdesc">
              同时支持串口数据到4G和以太网网络，支持远程登录
              与升级
            </div>
          </div>
        </div>
        <div class="router-item">
          <div class="router-item-icon-box">
            <i class="ri-psychotherapy-line ri-icon"></i>
          </div>
          <div class="router-item-text">
            <div class="router-item-subtitle">智能</div>
            <div class="router-item-subdesc">自带精简版Linux系统，主频高达580MHz</div>
          </div>
        </div>
        <div class="router-item no-border">
          <div class="router-item-icon-box">
            <i class="ri-rocket-2-line ri-icon"></i>
          </div>
          <div class="router-item-text">
            <div class="router-item-subtitle">高速</div>
            <div class="router-item-subdesc">4G无线上网+双频Wi-Fi 300Mbps</div>
          </div>
        </div>
      </div>
      <div class="dataVis-part">
        <div class="dataVis-title">
          <m-sub-title2>数据可视化</m-sub-title2>
          <div class="dataVis-desc">
            快捷、易用的数据可视化工具，企业可快速搭建
            设备可视化、数据大屏可视化
          </div>
          <div class="detail" @click="push('/m_dataVisualization', '数据可视化')">
            <div class="detail-text">了解详情</div>
            <img src="../assets/img/arrow_right_s.png" alt />
          </div>
          <div class="dataVis-img-part">
            <div>
              <div class="img-left img-left-1">丰富的数据组件</div>
              <div class="img-left img-left-2">多种数据源对接</div>
            </div>
            <div class="img-right">拖拽式编辑界面</div>
          </div>
        </div>
      </div>
    </div>
    <div class="customer-part">
      <m-sub-title2>来自客户的信任</m-sub-title2>
      <div class="item-desc">20年物联网行业经验，用心服务全球企业</div>
      <van-swipe class="my-swipe" indicator-color="#2E6BF6">
        <van-swipe-item class="swipe-item">
          <div class="customer-card">
            <div class="customer-card-title">
              <img class="customer-card-title-logo" src="../assets/img/sy_logo_welbilt.png" alt />
              <div class="customer-card-title-text">
                <div class="customer-card-title-word">Welbilt</div>
                <div class="customer-card-title-keyword-part">
                  <div class="customer-card-title-keyword">餐饮</div>
                  <div class="customer-card-title-keyword">咖啡机物联服务</div>
                </div>
              </div>
            </div>
            <div
              class="customer-card-desc"
            >Welbilt集团是全球餐饮设备行业的领导者,致力于为全球客户设计、制造和供应一流的餐饮设备。我们为其下咖啡机品牌CREM提供专业的咖啡机设备物联服务，助力其在共享商业领域的拓展。</div>
          </div>
        </van-swipe-item>
        <van-swipe-item class="swipe-item">
          <div class="customer-card">
            <div class="customer-card-title">
              <img class="customer-card-title-logo" src="../assets/img/sy_logo_lima.png" alt />
              <div class="customer-card-title-text">
                <div class="customer-card-title-word">LIMA立马</div>
                <div class="customer-card-title-keyword-part">
                  <div class="customer-card-title-keyword">交通</div>
                  <div class="customer-card-title-keyword">电动车物联服务</div>
                </div>
              </div>
            </div>
            <div
              class="customer-card-desc"
            >立马是国内知名电动车行业领导者,专业从事新能源电动车研发、生产制造、应用推广于一体的大型集团化高新技术企业。我们为其提供专业的智能化电动车物联服务，助力其在智能化电动车的布局。</div>
          </div>
        </van-swipe-item>
        <van-swipe-item class="swipe-item">
          <div class="customer-card">
            <div class="customer-card-title">
              <img class="customer-card-title-logo" src="../assets/img/sy_logo_sofo.png" alt />
              <div class="customer-card-title-text">
                <div class="customer-card-title-word">SOFO索弗</div>
                <div class="customer-card-title-keyword-part">
                  <div class="customer-card-title-keyword">按摩椅</div>
                  <div class="customer-card-title-keyword">按摩椅物联服务</div>
                </div>
              </div>
            </div>
            <div
              class="customer-card-desc"
            >索弗是一家集研发、实验、生产、行销和服务为—体的大型保健按摩器材的专业制造型集团企业。我们为其按摩椅提供专业的物联网服务,助力其在共享按摩椅的领域的领先。</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="bar-bottom">
      <div class="bar-bottom-title">生态合作计划</div>
      <div class="bar-bottom-desc">招募合作伙伴，共创IoT生态</div>
      <div class="bar-bottom-btn" @click="push('/m_eCooperation', '生态合作')">了解详情</div>
    </div>
  </div>
</template>

<script>
import MSubTitle1 from '../components/mobile/MSubTitle1.vue'
import MSubTitle2 from '../components/mobile/MSubTitle2.vue'
export default {
  components: {
    MSubTitle1,
    MSubTitle2,
  },
  methods: {    
		push (url, name, extra=false) {
			if (window?.location?.pathname === url) {
				return;
			}
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '移动端-首页', url]);
			}
			if (extra) {
				window.location.href = url;
			} else {
				this.$router.push(url);
			}
		}
  }
}
</script>

<style lang="less" scoped>
.m-index {
  width: 100%;
  height: 100%;
  
  overflow: hidden;
}
.title-box {
  width: 100%;
  height: 330px;
  background-image: url("../assets/img/banner_sy.png");
  .title {
    padding-top: 150px;
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
    text-shadow: 2px 2px 5px rgba(15, 29, 47, 0.3);
  }
  .desc {
    margin-top: 20px;
    font-size: 11px;
    padding: 0 36px;
    color: #ffffff;
    line-height: 17px;
    text-shadow: 2px 2px 5px rgba(15, 29, 47, 0.3);
    box-sizing: border-box;
  }
}
.content {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  text-align: left;
}
.sub-title-1 {
  font-size: 16px;
  font-weight: normal;
}
.item-desc {
  margin-top: 10px;
  font-size: 12px;
  color: #777f90;
  line-height: 20px;
}
.iot-img-group {
  display: flex;
  padding: 0 15px;
  margin-top: 15px;
  .iot-img {
    width: 173px;
    height: 125px;
  }
}
.icon {
  text-shadow: 1px 1px 2px rgba(119, 127, 144, 0.5);
}
.iot-item-part {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 125px;
  padding: 8px 0;
  box-sizing: border-box;
  margin-left: 10px;
  .iot-icon-item {
    width: 23px;
    height: 23px;
    background: #eaf0fe;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .iot-icon {
    color: #2e6bf6;
    text-shadow: 1px 1px 2px 0px rgba(46, 107, 246, 0.5);
  }
  .iot-text {
    font-size: 11px;
    color: #777f90;
    letter-spacing: 2px;
  }
  .iot-item {
    display: flex;
    align-items: center;
  }
}
.detail {
  margin-top: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  width: 68px;
  .detail-text {
    font-size: 12px;
    color: @main-color;
  }
  img {
    width: 10px;
    height: 10px;
    margin-left: 1px;
    vertical-align: middle;
  }
}
.router-part {
  margin-top: 15px;
  .no-border {
    border: 0 !important;
  }
  .router-item {
    display: flex;
    background-color: #fff;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 28px;
    border-bottom: 1px solid #eaf0fe;
    .router-item-icon-box {
      width: 30px;
      height: 30px;
      background: #eaf0fe;
      border-radius: 3px;
      text-align: center;
      line-height: 30px;
      color: #2e6bf6;
      text-shadow: 2px 2px 3px rgba(46, 107, 246, 0.5);
      margin-right: 10px;
      .ri-icon {
        width: 18px;
        height: 18px;
      }
    }
    .router-item-text {
      .router-item-subtitle {
        font-size: 14px;
        color: #0f1d2f;
        margin-bottom: 9px;
        line-height: 19px;
      }
      .router-item-subdesc {
        font-size: 11px;
        color: #777f90;
        line-height: 15px;
      }
    }
  }
}
.dataVis-part {
  display: flex;
  justify-content: center;
  text-align: center;
  .dataVis-title {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 28px;
    box-sizing: border-box;
    background-image: url("../assets/img/ksh.png");
  }
  .dataVis-desc {
    width: 252px;
    height: 40px;
    font-size: 12px;
    color: #777f90;
    line-height: 20px;
    margin: 0 auto;
    margin-top: 10px;
  }
  .detail {
    margin: 0 auto;
    margin-top: 15px;
  }
  .dataVis-img-part {
    width: 100%;
    height: 195px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    color: #ffffff;
    line-height: 13px;

    .img-left {
      width: 137px;
      height: 94.5px;
      padding-top: 77px;
      box-sizing: border-box;
    }
    .img-left-1 {
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../assets/img/sy_pho1.png);
    }
    .img-left-2 {
      margin-top: 6px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../assets/img/sy_pho2.png);
    }
    .img-right {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url(../assets/img/sy_pho3.png);
      flex: 1;
      height: 100%;
      margin-left: 6px;
      padding-top: 177px;
      box-sizing: border-box;
    }
  }
}
.customer-part {
  overflow:hidden;
  width: 100%;
  margin-top: 30px;
  text-align: center;
  
  .my-swipe {
    width: 100%;
    padding-top: 30px;
    height: 194px;
    .swipe-item {
      width: 100%;
      padding: 0 15px;
      display: flex;
      height: 160px;
      justify-content: center;
      box-sizing: border-box;
      .customer-card {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: rgba(46, 107, 246, 0.16) 2px 2px 10px 0;
      }
      &:nth-of-type(1) {
        .customer-card {
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0.68) 0%,
            #ffffff 100%
          );
          border-radius: 4px;
          width: 345px;
          height: 100%;
          background-size: 100% 100%;
          background-image: url("../assets/img/sy_kh_1.png");
        }
      }
      &:nth-of-type(2) {
        .customer-card {
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0.68) 0%,
            #ffffff 100%
          );
          border-radius: 4px;
          width: 345px;
          height: 100%;

          background-size: 100% 100%;
          background-image: url("../assets/img/sy_kh_2.png");
        }
      }
      &:nth-of-type(3) {
        .customer-card {
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0.68) 0%,
            #ffffff 100%
          );
          border-radius: 4px;
          width: 345px;
          height: 100%;

          background-size: 100% 100%;
          background-image: url("../assets/img/sy_kh_3.png");
        }
      }
    }
    /deep/ .van-swipe__indicators {
      bottom: 20px;
    }
    /deep/ .van-swipe__indicator {
      height: 4px;
      width: 4px;
      background: #d5e1fd;
    }
  }
  .customer-card-title {
    display: flex;
    align-items: center;
  }
  .customer-card-title-logo {
    width: 55px;
    height: 55px;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    margin-top: 16px;
    margin-bottom: 11px;
  }
  .customer-card-title-text {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }
  .customer-card-title-word {
    font-size: 16px;
    color: #0f1d2f;
    line-height: 21px;
    text-align: left;
    margin-bottom: 5px;
  }
  .customer-card-title-keyword-part {
    display: flex;
  }
  .customer-card-title-keyword {
    margin-right: 5px;
    padding: 2px 5px;
    border: 1px solid #2e6bf6;
    color: #2e6bf6;
    font-size: 10px;
  }
  .customer-card-desc {
    font-size: 11px;
    color: #777f90;
    line-height: 17px;
    text-align: justify;
  }
}
.bar-bottom {
  padding: 25px 0;
  background-size: 100% 100%;
  background-image: url("../assets/img/bottom_img_m.jpg");
  .bar-bottom-title {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    margin-bottom: 10px;
  }
  .bar-bottom-desc {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
    letter-spacing: 3px;
    margin-bottom: 18px;
  }
  .bar-bottom-btn {
    width: 79px;
    height: 22px;
    background: #ffffff;
    border-radius: 11px;
    margin: 0 auto;
    font-size: 9px;
    color: #2e6bf6;
    line-height: 22px;
  }
}
</style>

