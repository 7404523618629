export default function (text, type, time, top) {
  text = text || "确定删除？", time = time || 2000, top = top || "64px", type = type || 'success';//增加默认值，增强健壮性
  const body = document.getElementsByTagName("body")[0];//优化dom
  //实现alert
  const div = document.createElement("div");
  div.className = "animated bounceInDown alert ";
  div.className += type
  div.id = "alert";
  div.stylewidth = '400px';
  div.style.marginLeft = -200 + "px";
  div.innerHTML = text;
  body.appendChild(div);
  //动态调整位置
  setTimeout(function () {
    body.removeChild(div);
  }, time);
}