<template>
  <div class="footer">
    <div class="menu">
      <div class="menu-item-1">
        <div class="menu-item-1-title" @click="showListItem">
          <span>产品</span>
          <i class="ri-arrow-right-s-line" :class="{ 'ri-arrow-right-s-line-rotate': showList }"></i>
        </div>
        <div>
          <transition name="list">
            <div class="list" v-show="showList">
              <div class="list-item" @click="push('/m_iot', '物联网全连接云平台')">物联网全连接云平台</div>
              <div class="list-item" @click="push('/m_router', '智能工业路由器')">智能工业路由器</div>
              <div class="list-item" @click="push('/m_dataVisualization', '数据可视化')">数据可视化</div>
            </div>
          </transition>
        </div>
      </div>
      <a href="javascript:;" @click="push('https://help.senthink.com', '文档中心', true)" class="menu-item">
        <span>文档中心</span>
        <i class="ri-arrow-right-s-line"></i>
      </a>
      <div @click="push('/m_eCooperation', '生态合作')" class="menu-item">
        <span>生态合作</span>
        <i class="ri-arrow-right-s-line"></i>
      </div>
      <div @click="push('/m_about', '关于我们')" class="menu-item">
        <span>关于我们</span>
        <i class="ri-arrow-right-s-line"></i>
      </div>
    </div>
    <div class="info">
      <div class="phone">18167172990</div>
      <div class="name">详情咨询-王先生</div>
      <div class="fac">杭州贤芯科技有限公司</div>
      <div class="email">邮箱</div>
      <div class="email-account">support@senthink.com</div>
      <div class="addres">地址</div>
      <div class="addres-text">杭州市文一西路1326号利尔达科技园1号</div>
      <div class="copy-right">
        © CopyRight 2015-2022, SENTHINK.COM, Inc.All Rights Reserved.
        浙ICP备15040917号
      </div>
      <img class="QR" src="../../assets/img/ewm.png" alt />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showList: false
    }
  },
  methods: {
    showListItem() {
      this.showList = !this.showList
    },
		push (url, name, extra=false) {
			if (window?.location?.pathname === url) {
				return;
			}
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '移动端-底部', url]);
			}
			if (extra) {
				window.location.href = url;
			} else {
				this.$router.push(url);
			}
		}
  }
}
</script>

<style lang="less" scoped>
.footer {
  background: #16171b;
  padding: 0 15px;
  text-align: left;
  position: relative;
}
.menu {
  padding-top: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .menu-item {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    line-height: 45px;
    border-bottom: 1px solid rgba(234, 240, 254, 0.2);
    font-size: 13px;
    color: #ffffff;
  }
  .menu-item-1 {
    border-bottom: 1px solid rgba(234, 240, 254, 0.2);
    .menu-item-1-title {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      line-height: 45px;
      font-size: 13px;
      color: #ffffff;
    }
  }
}
.phone {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  line-height: 26px;
  margin-top: 30px;
  margin-bottom: 5px;
}
.name {
  font-size: 13px;
  color: #ffffff;
  line-height: 18px;
  opacity: 0.6;
  margin-bottom: 25px;
}
.fac {
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  line-height: 18px;
}
.email,
.addres {
  font-size: 12px;
  color: #ffffff;
  line-height: 16px;
  margin-top: 20px;
}
.email-account,
.addres-text {
  font-size: 12px;
  color: #ffffff;
  line-height: 16px;
  opacity: 0.6;
  margin-top: 10px;
}
.copy-right {
  font-size: 9px;
  border-top: 1px solid rgba(234, 240, 254, 0.4);
  opacity: 0.6;
  padding-top: 30px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
  margin-top: 30px;
  line-height: 16px;
}
.info{
  position: relative;
}
.QR {
  position: absolute;
  top: 101px;
  right: 10px;
  width: 80px;
  height: 80px;
}
.ri-arrow-right-s-line {
  font-size: 16px;
}
.list {
  transition: all linear 0.3s;
  opacity: 0.6;
  overflow: hidden;
  position: relative;
  text-align: left;
  font-size: 13px;
  color: #ffffff;
  line-height: 18px;
  padding: 0 10px;
  padding-bottom: 12px;
  .list-item {
    padding: 8px 0;
  }
}
.list-enter-active,
.list-leave-active {
  transition: "opacity" 0.5s ease;
  transition: "max-height" 0.5 s ease;
}
.list-enter, .list-leave-to /* .fade-leave-active below version 2.1.8 */ {
  max-height: 0;
  opacity: 0;
}
.list-enter-to,
.list-leave {
  max-height: 138px;
}
.ri-arrow-right-s-line-rotate {
  transform: rotate(90deg);
}
</style>