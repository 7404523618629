<template>
	<section class="section section_2">
		<sub-title-2 class="section_2-title">客户场景</sub-title-2>
		<div class="sub-title">专业物联网解决方案，倾力满足企业所需</div>
		<div class="list">
			<div class="item">
				<div class="title">助力企业IOT开发</div>
				<ul class="content">
					<li>
						<div class="icon_problem"></div>
						<div>
							<div class="title">客户痛点</div>
							<div>传统硬件生产企业自建平台门槛高，云厂商套件复杂，入门困难。</div>
						</div>
					</li>
					<li>
						<div class="icon_resolve"></div>
						<div>
							<div class="title">解决方案</div>
							<div>产品厂家专心做好自己擅长部分，我们帮助企业快速构建物联网应用程序。</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="item">
				<div class="title">私有化按需定制</div>
				<ul class="content">
					<li>
						<div class="icon_problem"></div>
						<div>
							<div class="title">客户痛点</div>
							<div>若严重依赖云厂商会被生态裹挟，可定制化弱，内网部署强需求。</div>
						</div>
					</li>
					<li>
						<div class="icon_resolve"></div>
						<div>
							<div class="title">解决方案</div>
							<div>自主可控，按需定制，公有云、私有云、企业机房灵活部署。</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>
<script>
import SubTitle2 from './SubTitle2.vue'
export default {
	components: { SubTitle2 },
}
</script>
<style lang="less" scoped>
.section_2-title {
	margin: 66px auto 36px;
}
.section_2 {
	padding-bottom: 57px;
	background: #f9fbff url("../assets/img/bg_8.png") no-repeat 0 144px;
	.sub-title {
		font-size: 16px;
		color: #999999;
		margin-bottom: 76px;
	}

	.sub-title > div {
		font-size: 16px;
		color: #999999;
	}

	.list {
		display: inline-flex;
		margin: 0 auto;
	}

	.list .item {
		position: relative;
		width: 520px;
		height: 470px;
		background: #fff;
		box-shadow: 0px 0px 10px rgba(0, 59, 140, 0.1);
	}

	.list .item + .item {
		margin-left: 82px;
	}

	.item > .title {
		position: absolute;
		width: 100%;
		font-size: 20px;
		color: #5a5a5a;
		top: 27px;
		text-align: center;
		text-decoration: none;
	}

	.item:hover {
		box-shadow: 0px 0px 22px rgba(0, 59, 140, 0.1);
	}

	.item:hover > .title {
		font-size: 22px;
		color: #026def;
		font-weight: bold;
	}

	.item .content {
		margin-top: 139px;
	}

	.item .content li {
		display: flex;
		flex-direction: row;
		height: 80px;
		text-align: left;
		margin-top: 75px;
		padding-right: 54px;
	}
	.icon_problem {
		width: 70px;
		height: 70px;
		margin: 5px 30px 5px 41px;
		padding: 5px 30px 5px 41px;
		background: url("../assets/img/icon_problem.png") no-repeat;
	}

	.icon_resolve {
		width: 70px;
		height: 70px;
		margin: 5px 30px 5px 41px;
		padding: 5px 30px 5px 41px;
		background: url("../assets/img/icon_resolve.png") no-repeat;
	}
	.item .content li > div div:not(.title) {
		font-size: 14px;
		color: #999999;
		line-height: 32px;
		margin-top: 16px;
	}
	.item .content li div.title {
		font-size: 22px;
		color: #333333;
		line-height: 23px;
	}
}
</style>