<template>
  <div class="banner" :style="{ backgroundImage: `url(${url})` }">
    <div class="top">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div class="img">
        <slot name="img"></slot>
      </div>
    </div>
    <div class="desc">
      <slot name="desc"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['url']
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 260px;
  box-sizing: border-box;
  padding: 0 15px;
  background-size: 100% 100%;
  .top {
    padding-top: 66px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 5px;
  }
  .title {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
    padding-bottom: 15px;
  }
  .img img {
    width: 173px;
    height: 122px;
  }
  .desc {
    font-size: 11px;
    color: #ffffff;
    line-height: 17px;
    text-align: left;
  }
}
</style>