<template>
  <div class="m-router">
    <m-banner :url="require('../../assets/img/banner_zngylyq.png')">
      <template #title>智能工业路由器</template>
      <template #img>
        <img src="../../assets/img/router.gif" alt />
      </template>
      <template #desc>是一款利用无线网络为M2M行业提供无线数据传输功能，无缝对接物联网全连接云平台，解决非IoT设备数据采集和远程控制</template>
    </m-banner>
    <div class="content">
      <m-sub-title1 class="sub-title1">产品介绍</m-sub-title1>
      <div class="introduction">
        <img src="../../assets/img/lyq_img1.png" alt />
        <div class="router-name">4G智能工业路由器</div>
        <div
          class="router-desc"
        >采用成熟的MTK（联发科）系列芯片，主频高580MHz，内部设计1G以上存储空间，拥有RS485和RS232串口，可同时实现串口数据到4G和以太网网络。开发者可使用JS语言解析任意自定义协议数据（支持ModBus协议）实现快速开发并可远程升级，加速非IoT设备数据采集和远程控制。</div>
        <div class="router-params">产品参数</div>
        <div class="router-params-list">
          <div class="router-params-list-item">
            <div class="dot"></div>
            <span class="router-list-params-text">自带精简版Linux系统（基于OpenWrt）</span>
          </div>
          <div class="router-params-list-item">
            <div class="dot"></div>
            <span class="router-list-params-text">JS脚本快速开发解析协议</span>
          </div>
          <div class="router-params-list-item">
            <div class="dot"></div>
            <span class="router-list-params-text">内部自带1G以上存储</span>
          </div>
          <div class="router-params-list-item">
            <div class="dot"></div>
            <span class="router-list-params-text">双串口，双USB</span>
          </div>
          <div class="router-params-list-item">
            <div class="dot"></div>
            <span class="router-list-params-text">4G无线上网+双频Wi-Fi 300Mbps</span>
          </div>
        </div>
      </div>
      <m-sub-title2 class="sub-title2">产品优势</m-sub-title2>
      <div class="advantages">
        <div class="advantages-item">
          <img src="../../assets/img/lyq_ys1.png" alt />
          <div class="advantages-item-text">
            <div class="advantages-item-text-title">支持多种通信方式</div>
            <div class="advantages-item-text-desc">
              支持RS485、RS232、以太网等有线数据传输；
              以及Wi-Fi、4G等无线数据传输
            </div>
          </div>
        </div>
        <div class="advantages-item">
          <img src="../../assets/img/lyq_ys2.png" alt />
          <div class="advantages-item-text">
            <div class="advantages-item-text-title">快速解析自定义协议</div>
            <div class="advantages-item-text-desc">使用JavaScript解析任意自定义协议数据， 降低传统开发时间</div>
          </div>
        </div>
        <div class="advantages-item no-border">
          <img src="../../assets/img/lyq_ys3.png" alt />
          <div class="advantages-item-text">
            <div class="advantages-item-text-title">灵活部署与维护</div>
            <div class="advantages-item-text-desc">内置web界面，支持配置参数，无缝对接物联网全连接云平台</div>
          </div>
        </div>
      </div>
      <div class="transmit">传输方式</div>
      <img class="transmit-title-img" src="../../assets/img/title_center_blue.png" alt />
      <img class="transmit-img" src="../../assets/img/csfs.png" alt />
      <div class="watch-img" @click="showImg">点击查看架构图</div>
      <m-sub-title2 class="sub-title2">应用场景</m-sub-title2>
      <div class="scenario">
        <div class="scenario-item">
          <img src="../../assets/img/lyq_pho1.png" alt />
          <div class="scenario-item-name">智慧建筑检测</div>
        </div>
        <div class="scenario-item">
          <img src="../../assets/img/lyq_pho2.png" alt />
          <div class="scenario-item-name">矿区数据采集</div>
        </div>
        <div class="scenario-item">
          <img src="../../assets/img/lyq_pho3.png" alt />
          <div class="scenario-item-name">工业物联网</div>
        </div>
      </div>
    </div>
    <m-bottom-banner>
      <template #title>是否对我们的产品感兴趣了呢？</template>
      <template #desc>期待与您合作！</template>
    </m-bottom-banner>
  </div>
</template>

<script>
import MBanner from "../../components/mobile/MBanner"
import MSubTitle1 from '../../components/mobile/MSubTitle1'
import MSubTitle2 from "../../components/mobile/MSubTitle2"
import { ImagePreview } from 'vant';
import MBottomBanner from '../../components/mobile/MBottomBanner'
export default {
  components: {
    MBanner,
    MSubTitle1,
    MSubTitle2,
    MBottomBanner
  },
  data() {
    return {
      transmitImgShow: false
    }
  },
  methods: {
    showImg() {
      ImagePreview([
        require('../../assets/img/csfs.png'),
      ]);
    }
  }
}
</script>

<style lang="less" scoped>
.sub-title1 {
  text-align: left;
  display: flex;
  justify-content: left;
}
.sub-title2 {
  margin-top: 30px;
  margin-bottom: 20px;
}
.m-router {
  text-align: center;
}
.content {
  padding: 0 15px;
}
.introduction {
  text-align: center;
  margin-top: 20px;
  width: 100%;
  height: 505px;
  background: #ffffff;
  padding: 0 10px;
  box-sizing: border-box;
  img {
    margin-top: 10px;
    width: 125px;
    height: 142px;
  }
  .router-name {
    text-align: left;
    font-size: 13px;
    color: #16171b;
    line-height: 18px;
    margin-top: 20px;
  }
  .router-desc {
    text-align: justify;
    font-size: 12px;
    margin-top: 10px;
    color: #777f90;
    line-height: 20px;
  }
  .router-params {
    margin-top: 40px;
    font-size: 13px;
    color: #16171b;
    line-height: 18px;
    text-align: left;
  }
  .router-params-list {
    text-align: left;
  }
  .router-params-list-item {
    font-size: 12px;
    color: #777f90;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .dot {
      width: 6px;
      height: 6px;
      background: #777f90;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.advantages {
  width: 100%;
  box-sizing: border-box;
  height: 346px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .advantages-item {
    padding: 0 20px 0 10px;
    display: flex;
    text-align: left;
    height: 115px;
    padding-top: 15px;
    border-bottom: 1px solid #eaf0fe;
    img {
      width: 44px;
      height: 44px;
    }
    .advantages-item-text {
      padding-top: 20px;
      margin-left: 15px;
      .advantages-item-text-title {
        font-size: 14px;
        color: #0f1d2f;
        line-height: 19px;
      }
      .advantages-item-text-desc {
        margin-top: 8px;
        font-size: 11px;
        color: #777f90;
        line-height: 18px;
      }
    }
  }
}
.no-border {
  border: none !important;
}
.transmit {
  margin-top: 30px;
  font-size: 16px;
  color: #0f1d2f;
  line-height: 21px;
}
.transmit-title-img {
  width: 87px;
  height: 6px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.transmit-img {
  width: 100%;
  height: 120px;
  margin-bottom: 12px;
}
.watch-img {
  width: 100%;
  height: 34px;
  background: #d5e1fd;
  font-size: 12px;
  color: #2e6bf6;
  line-height: 34px;
}
.transmit-popup-img {
  width: 100vw;
}
.scenario {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  .scenario-item {
    img {
      width: 111px;
      height: 75px;
    }
    .scenario-item-name {
      margin-top: 9px;
      font-size: 10px;
      color: #0f1d2f;
      line-height: 13px;
    }
  }
}
</style>