<template>
  <div>
    <div class="title-part">
      <div class="title-part-bg">
        <div class="title">生态合作计划</div>
        <div class="desc">
          不管您是硬件厂商，还是行业领域系统公司，我们都
          <br />诚邀您一起共建物联网生态，共同为客户提供
          <br />更专业的服务，助力业务快速增长
          <br />
        </div>
      </div>
    </div>
    <m-sub-title-2 class="sub-title2">为何加入生态伙伴？</m-sub-title-2>
    <div class="why">
      <div class="list">
        <div class="list-item">
          <i class="ri-hand-coin-line item-icon"></i>
          <div class="item-text">
            <div class="item-title">技术赋能</div>
            <div class="item-desc">专注各自领域，共同为客户提供更专业的服务</div>
          </div>
        </div>
        <div class="list-item">
          <i class="ri-road-map-line item-icon"></i>
          <div class="item-text">
            <div class="item-title">联合营销</div>
            <div class="item-desc">
              母公司-利尔达集团全国拥有20多个办事处，可协助合作
              伙伴共同开展市场活动
            </div>
          </div>
        </div>
        <div class="list-item no-border">
          <i class="ri-service-line item-icon"></i>
          <div class="item-text">
            <div class="item-title">商机合作</div>
            <div class="item-desc">商机信息及时共享，助力双方业务快速增长</div>
          </div>
        </div>
      </div>
    </div>
    <div class="number-bg">
      <div class="number-part">
        <number class="number" :max="20" :title="'物联网从业经验'">年</number>
        <number class="number" :max="500" :title="'接入智能化设备'">万+</number>
        <number class="number" :max="100" :title="'软著与专利'">+</number>
        <number class="number" :max="500" :title="'客户与项目'">+</number>
      </div>
    </div>
    <m-sub-title-2 class="sub-title2">招募对象</m-sub-title-2>
    <div class="object-part">
      <div class="object">
        <div class="object-group">
          <div class="object-list border-right">
            <div class="object-list-title">硬件设备厂商</div>
            <div class="object-list-item-part">
              <div class="object-list-item">模组厂家</div>
              <div class="object-list-item">智能设备厂家</div>
              <div class="object-list-item no-border">传统设备厂家</div>
            </div>
          </div>
          <div class="object-list">
            <div class="object-list-title">数字孪生可视化公司</div>
            <div class="object-list-item-part">
              <div class="object-list-item">CIM</div>
              <div class="object-list-item">BIM</div>
              <div class="object-list-item no-border">3D</div>
            </div>
          </div>
        </div>
        <div class="object-group">
          <div class="object-list border-right">
            <div class="object-list-title">行业业务系统公司</div>
            <div class="object-list-item-part">
              <div class="object-list-item">AI算法</div>
              <div class="object-list-item">大数据分析</div>
              <div class="object-list-item no-border">垂直应用</div>
            </div>
          </div>
          <div class="object-list">
            <div class="object-list-title">系统集成商</div>
            <div class="object-list-item-part">
              <div class="object-list-item">智慧园区</div>
              <div class="object-list-item">智慧医院</div>
              <div class="object-list-item no-border">智慧工地</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <m-bottom-banner>
      <template #title>立刻成为生态伙伴</template>
      <template #desc>期待与您携手共创万物互联时代！</template>
    </m-bottom-banner>
  </div>
</template>

<script>
import MBottomBanner from '../../components/mobile/MBottomBanner.vue'
import MSubTitle2 from '../../components/mobile/MSubTitle2.vue'
import Number from '../../components/Number.vue'
export default {
  components: { MSubTitle2, Number, MBottomBanner },
}
</script>

<style lang="less" scoped>
.title-part {
  width: 100%;
  height: 260px;
  background: url("../../assets/img/banner_sthz.png");
  background-size: 100% 100%;
}
.title-part-bg {
  width: 100%;
  height: 100%;
  background: rgba(22, 23, 27, 0.5);
  padding-top: 145px;
  box-sizing: border-box;
  .title {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
  }
  .desc {
    margin-top: 10px;
    font-size: 11px;
    color: #ffffff;
    line-height: 17px;
  }
}
.why {
  padding: 0 15px;
}
.number-bg {
  width: 100%;
  height: 230px;
  background: url("../../assets/img/sthz_bg1.png");
  background-size: 100% 100%;
}
.number-part {
  margin-top: 20px;
  width: 100%;
  height: 160px;
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .number {
    height: 48px;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /deep/ #number {
      font-size: 16px;
    }
    /deep/ .title {
      font-size: 10px;
      margin-top: 10px;
    }
  }
}
.object-part {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  .object {
    width: 100%;
    height: 400px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
  }
  .object-group {
    flex: 1;
    background-image: url("../../assets/img/sthz_bg2_m.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    display: flex;
  }
  .object-list {
    height: 100%;
    flex: 1;
    .object-list-title {
      height: 33px;
      background: #777f90;
      color: #ffffff;
      font-size: 12px;
      line-height: 33px;
    }
    .object-list-item-part {
      padding: 22px 29px 0;
      font-size: 11px;
      color: #777f90;
      line-height: 35px;
    }
    .object-list-item {
      border-bottom: 1px solid #eaf0fe;
    }
  }
}
.list {
  text-align: left;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  .list-item {
    padding: 18px 24px 0 27px;
    box-sizing: border-box;
    height: 90px;
    display: flex;
    border-bottom: 1px solid #eaf0fe;
  }
  .item-text {
    padding-top: 3px;
  }
  .item-title {
    font-size: 14px;
    color: #0f1d2f;
    line-height: 19px;
  }
  .item-desc {
    font-size: 11px;
    margin-top: 9px;
    color: #777f90;
    line-height: 18px;
  }
  .item-icon {
    font-size: 26px;
    color: #2e6bf6;
    margin-right: 12px;
  }
}
.no-border {
  border: none !important;
}
.border-right {
  border-right: 1px solid #eaf0fe;
}
.sub-title1 {
  text-align: left;
  display: flex;
  justify-content: left;
}
.sub-title2 {
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>