<template>
	<div>
		<title-h :title='titleBox'></title-h>
		<div class="part1">
			<sub-title-2 class="zero-title">零代码拖拽，快速搭建</sub-title-2>
			<div class="circle-box-list">
				<circle-box :content="circleBox[0]"><i class="ri-drag-drop-line"></i></circle-box>
				<circle-box :content="circleBox[1]"><i class="ri-flow-chart"></i></circle-box>
				<circle-box :content="circleBox[2]"><i class="ri-archive-line"></i></circle-box>
			</div>
		</div>
		<div
			class="part2"
			:style="{backgroundPositionY:backgroundPositionY,transition:transition}"
		>
			<div class="base-overlay">
				<div class="base-box">
					<div class="base-template">
						<div class="base-title">基础模板，一键使用</div>
						<div class="base-describe">
							提供持续丰富的大屏模版不断满足各行业展示需求，可在
							模版基础之上快速修改生成企业专属大屏
						</div>
						<img
							src="../../assets/img/title_big_wight.png"
							alt=""
						>
					</div>
				</div>
			</div>
		</div>
		<div class="part3">
			<div class="part3-box">
				<div class="img"></div>
				<div class="part3-content">
					<div class="sub-title-font">灵活部署与发布</div>
					<div class="sub-title-describe-font">
						一键生成HTML分享链接，支持加密发布，可通过PC、iPad、手机浏览器访问
					</div>
					<img
						src="../../assets/img/title_big_blue.png"
						alt=""
					>
				</div>
			</div>
		</div>
		<div class="part4">
			<sub-title-2 class="case">应用案例</sub-title-2>
			<ul>
				<li>
					<div class="case-img">
						<img
							src="../../assets/img/ksh_pho1.png"
							alt=""
						>
					</div>
					<div class="case-title">智慧工业</div>
				</li>
				<li>
					<div class="case-img">
						<img
							src="../../assets/img/ksh_pho2.png"
							alt=""
						>
					</div>
					<div class="case-title">智慧城市</div>
				</li>
				<li>
					<div class="case-img">
						<img
							src="../../assets/img/ksh_pho3.png"
							alt=""
						>
					</div>
					<div class="case-title">智慧商业</div>
				</li>
			</ul>
			<sub-title-2 class="service">服务模式</sub-title-2>
			<ul>
				<li>
					<div class="service-box">
						<circle-box
							class="service-content"
							:content="serviceBox[0]"
						><i class="ri-map-pin-time-line"></i></circle-box>
					</div>
				</li>
				<li>
					<div class="service-box">
						<circle-box
							class="service-content"
							:content="serviceBox[1]"
						><i class="ri-organization-chart"></i></circle-box>
					</div>
				</li>
			</ul>
		</div>
		<x-footer-2
			:title="'是否对我们的产品感兴趣了呢？'"
			:describe="'期待与您合作！'"
		></x-footer-2>
	</div>
</template>
<script>
import CircleBox from '../../components/CircleBox.vue'
import SubTitle1 from '../../components/SubTitle1.vue'
import SubTitle2 from '../../components/SubTitle2.vue'
import TitleH from '../../components/TitleH.vue'
import XFooter2 from '../../components/xFooter2.vue'
export default {
	components: { TitleH, SubTitle2, CircleBox, SubTitle1,XFooter2 },
	data() {
		return {
			backgroundPositionY: '0',
			transition: '3s all linear',
			serviceBox: [
				{
					title: '免费试用体验',
					describe: '限时免费试用，快来联系我们'
				},
				{
					title: '私有部署需求',
					describe: '对数据敏感？有定制化需求？'
				},
			],
			circleBox: [
				{
					title: '拖拽式编辑界面',
					describe: '免编程快速开发大屏\n图表数据直观展示'
				},
				{
					title: '丰富的数据组件',
					describe: '开放组件基础配置项\n可自定义组件样式'
				},
				{
					title: '多种数据源对接',
					describe: '静态、API、数据库自定义接入\n无缝对接物联网全连接云平台'
				}
			],
			titleBox: {
				title: '数据可视化',
				describe: '为企业提供快速搭建实时数据大屏工具，零代码拖拽方式轻松搭建数据可视化大屏',
				img: require('../../assets/img/sjksh.gif'),
				background: require('../../assets/img/banner_ksh.jpg')
			}

		}
	},
	mounted() {
		let part2 = document.getElementsByClassName('part2')[0];
		this.positionY(part2);
	},
	methods: {
		positionY(p) {
			let ob = new IntersectionObserver((entries) => {
				if (entries[0].intersectionRatio > 0) {
					this.backgroundPositionY = '100%'
					this.transition = '10s all linear'
				}
				else {
					this.backgroundPositionY = '0'
					this.transition = ''
				}
			})
			ob.observe(p)
		}
	}
}
</script>
<style lang="less" scoped>
.part1 {
	.zero-title {
		margin-top: 90px;
	}
	.circle-box-list {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		margin-top: 60px;
		white-space: pre;
	}
}
.part2 {
	height: 570px;
	margin-top: 90px;
	width: 100%;
	background: url("../../assets/img/ksh_bg1.jpg") no-repeat;
	background-size: cover;
	transition: 1s all linear;
	position: relative;
	.base-overlay {
		text-align: left;
		position: absolute;
		width: 100%;
		height: 570px;
		background: rgba(22, 23, 27, 0.6);
		.base-box {
			max-width: @max-width;
			width: 100%;
			min-width: @min-width;
			margin: 0 auto;
		}
		.base-template {
			height: 176px;
			width: 350px;
			display: flex;
			margin-left: 90px;
			margin-top: 190px;
			flex-direction: column;
			justify-content: space-between;
			.base-title {
				height: 37px;
				font-size: 28px;
				font-family: MicrosoftYaHei-Bold, Microsoft YaHei,-apple-system;
				font-weight: bold;
				color: #ffffff;
				line-height: 37px;
			}
			.base-describe {
				width: 350px;
				height: 38px;
				font-size: 14px;
				color: #ffffff;
				line-height: 19px;
			}
			img {
				height: 21px;
				width: 102px;
			}
		}
	}
}
.part3 {
	background: url("../../assets/img/ksh_bg2.png");
	height: 570px;
	width: 100%;
	.part3-box {
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		height: 100%;
		margin: 0 auto;
		position: relative;
		.part3-content {
			position: absolute;
			text-align: left;
			left: 873px;
			top: 200px;
			height: 176px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			img {
				height: 21px;
				width: 102px;
			}
		}
		.img {
			position: absolute;
			width: 834px;
			height: 489px;
			background: #ffffff;
			box-shadow: 3px -4px 10px 0px rgba(46, 107, 246, 0.14);
			border-radius: 8px 8px 0px 0px;
			background: url("../../assets/img/ksh_img1.png");
			bottom: 0;
		}
	}
}
.part4 {
	margin-bottom: 90px;
	.case {
		margin-top: 90px;
	}
	ul {
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		margin: 0 auto;
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		.case-img {
			width: 387px;
			height: 260px;
			overflow: hidden;
		}
		img {
			transition: 0.2s all linear;
		}
		img:hover {
			transform: scale(1.05);
		}
		.case-title {
			width: 100%;
			height: 21px;
			font-size: 16px;
			font-family: -apple-system, Microsoft YaHei;
			color: #0f1d2f;
			line-height: 21px;
			margin-top: 20px;
		}
	}
	.service {
		margin-top: 90px;
	}
	.service-box {
		width: 590px;
		height: 300px;
		border: 1px solid @border-color;
		.service-content {
			margin: 0 auto;
			margin-top: 40px;
		}
	}
}
</style>
