<template>
	<div class="m-sub-title-1" >
		<div  class="m-sub-title-font" >
			<slot></slot>
		</div>
		<img src="../../assets/img/title_right_blue.png" alt />
	</div>
</template>
<script>
export default {
}
</script>
<style lang="less" scoped>
.m-sub-title-1 {
	display: inline-flex;
	align-items: center;
	margin-top: 30px;
	img {
		height: 100%;
    height: 6px;
    width: 51px;
		margin-left: 10px;
	}
}
</style>