<template>
	<section class="section section_6">
		<sub-title-2 class="section_6-title">成功案例</sub-title-2>
		<div class="sub-title">
			<div>我们为企业提供云端物联网解决方案，让您的系统私有化，数据私有化</div>
		</div>
		<div class="content">
			<div class="nav">
				<ul>
					<li
						id="nav_6"
						class="section_6_nav"
					>
						楼宇检测
					</li>
					<li
						id="nav_7"
						class="section_6_nav"
					>
						共享按摩椅
					</li>
					<li
						id="nav_8"
						class="section_6_nav"
					>
						智能咖啡机
					</li>
				</ul>
			</div>
			<div class="content nav_6">
				<div class="left">
					<div>
						<header class="title">楼宇检测</header>
						<content class="detail">写字楼中的传感器数据上报，平台将数据转发至楼宇应用系统，楼宇系统收到数据后对数据进行分析和可视化展示。</content>
					</div>
					<div>
						<header class="title">物联网平台提供</header>
						<ul>
							<li>
								<div>设备接入</div>
								<div class="detail">平台保持与设备稳定连接。</div>
							</li>
							<li>
								<div>高并发</div>
								<div class="detail">传感器数据上报非常频繁，通过负载均衡和集群架构解决高并发。</div>
							</li>
							<li>
								<div>数据流转</div>
								<div class="detail">设备数据上报至平台后第一时间推送至应用订阅的服务组件，低延时。</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="right">

				</div>
			</div>
			<div class="content nav_7">
				<div class="left">
					<div>
						<header class="title">共享按摩椅</header>
						<content class="detail">共享按摩椅将在线状态和使用状态等信息上报云端，用户扫码后云端低延时下发指令控制按摩椅运行。</content>
					</div>
					<div>
						<header class="title">物联网平台提供</header>
						<ul>
							<li>
								<div>通信安全</div>
								<div class="detail">设备身份认证，双向通信加密，确保安全可靠</div>
							</li>
							<li>
								<div>低延时</div>
								<div class="detail">确保指令下发的及时性，提升用户扫码使用的体验</div>
							</li>
							<li>
								<div>实时监控</div>
								<div class="detail">实时监控设备在线状态，并第一时间与应用同步，确保按摩椅运营者及时掌握设备运行情况</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="right">
				</div>
			</div>
			<div class="content nav_8">
				<div class="left">
					<div>
						<header class="title">智能咖啡机</header>
						<content class="detail">咖啡机连云后，云上可实时感知设备状态，通过OTA方式升级媒体、菜单和配置，实现咖啡机智能精细化运营，起到降本增效的作用。</content>
					</div>
					<div>
						<header class="title">物联网平台提供</header>
						<ul>
							<li>
								<div>OTA升级</div>
								<div class="detail">指定升级策略，包含群组、时间、版本号等</div>
							</li>
							<li>
								<div>低成本</div>
								<div class="detail">远程监控设备状态和下发资料更新，大大节省了人工成本</div>
							</li>
							<li>
								<div>私有化</div>
								<div class="detail">企业数据私有化、系统私有化，保障数据资产安全</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="right">
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import SubTitle2 from './SubTitle2.vue';
export default {
	components: { SubTitle2 },
	mounted() {
		this.init5();
		this.changeClass2();
	},
	data() {
		return {
			items2: ['nav_6', 'nav_7', 'nav_8'],
			active5: 'nav_6',
		}
	},
	methods: {
		changeClass2() {
			for (let j = 0; j < this.items2.length; j++) {
				const nav = document.getElementById(this.items2[j]);
				const content = document.getElementsByClassName(this.items2[j])[0];
				if (!content) {
					break;
				}
				if (this.active5 === this.items2[j]) {
					nav.className = nav.className.indexOf('active') > -1 ? nav.className : nav.className + ' active';
					content.style.display = 'flex';
				} else {
					nav.className = nav.className.indexOf('active') > -1 ? nav.className.replace(' active', '') : nav.className;
					content.style.display = 'none';
				}
			}
		},
		init5() {
			const list = document.getElementsByClassName('section_6_nav');
			for (let i = 0; i < list.length; i++) {
				const self=this
				list[i].onclick = function () {
					self.active5 = list[i].id;
					self.changeClass2();
				}
			}
		}
	}
}
</script>
<style lang="less" scoped>
.section {
	text-align: center;
	width: 100%;
}
.section .sub-title {
	font-size: 16px;
	color: #999999;
	margin-bottom: 70px;
}

.section .sub-title > div {
	font-size: 16px;
	color: #999999;
}
.section_6-title {
	margin-top: 53px;
	margin-bottom: 40px;
}
.section_6 {
	width: 100%;
	height: 1070px;
	background: #f9fbff url(../assets/img/bg_1.png) no-repeat 0 703px;
	background-size: 100% 330px;
	text-align: center;
}
.section_6 header {
	margin-bottom: 30px;
}
.section_6 header .sub-title {
	margin-bottom: 68px;
	font-size: 16px;
}
.section_6 .content {
	width: 1200px;
	height: 560px;
	display: inline-block;
	background: #fff;
	box-shadow: 0 6px 20px 0 rgba(1, 109, 255, 0.1);
}
.section_6 .content .nav ul {
	width: 100%;
	height: 76px;
	border-bottom: 1px solid #e2e2e2;
	text-align: center;
	display: inline-block;
}
.section_6 .content .nav ul li {
	font-size: 20px;
	color: #333333;
	cursor: pointer;
	line-height: 76px;
	width: 110px;
	display: inline-flex;
}
.section_6 .content .nav ul li + li {
	margin-left: 80px;
}
.section_6 .content .nav ul li:hover,
.section_6 .content .nav ul li.active {
	color: #026dff;
	font-weight: bold;
	position: relative;
}
.section_6 .content .nav ul li.active {
	border-bottom: 2px solid #026dff;
}
.section_6 .content > .content {
	height: 484px;
}
.section_6 .content > .content .left {
	width: 526px;
	padding: 50px 50px 0;
}
.section_6 .content > .content .left > div {
	text-align: left;
}
.section_6 .content > .content .left > div .title {
	font-size: 24px;
	color: #333333;
	margin-top: 0;
	padding: 0;
}
.section_6 .content > .content .left > div .detail {
	font-size: 14px;
	color: #999999;
	line-height: 32px;
}
.section_6 .content > .content .left > div ul {
	display: flex;
	flex-direction: row;
}
.section_6 .content > .content .left > div ul li {
	flex: 1;
	padding: 0 22px 22px 0;
}
.section_6 .content > .content .left > div ul li > div:first-of-type {
	font-size: 18px;
	color: #333333;
	position: relative;
	margin-left: 20px;
}
.section_6 .content > .content .left > div ul li > div:first-of-type:before {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	background: #026dff;
	border-radius: 50%;
	left: -20px;
	top: 35%;
}
.section_6 .content > .content .left > div ul li .detail {
	line-height: 24px;
}
.section_6 .content > .content .left > div:last-of-type {
	margin-top: 50px;
}
.section_6 .content > .content .left:hover {
	box-shadow: 0px 6px 20px 0px rgba(2, 109, 255, 0.1);
}
.section_6 .content > .content .right {
	width: 674px;
	background: url("../assets/img/section_6_bg_1.png") no-repeat center
		86px;
}
.section_6 .content > .content .right:hover {
	box-shadow: 0px 6px 20px 0px rgba(2, 109, 255, 0.1);
}
.section_6 .content > .content.nav_7 .right {
	background-image: url("../assets/img/section_6_bg_2.png");
}
.section_6 .content > .content.nav_8 .right {
	background-image: url("../assets/img/section_6_bg_3.png");
}
</style>