<template>
	<div class="number-box">
		<div id="number">
			{{ number }}
			<slot></slot>
		</div>
		<div class="title">{{ title }}</div>
	</div>
</template>
<script>
export default {
	props: {
		max: {
			default: 20,
			type: Number,
			require: true,
		},
		title: {
			type: String
		}
	},
	data() {
		return {
			number: 0,
		}
	},
	mounted() {
		let number = document.getElementById('number')
		this.addNumber(number, 80);
	},
	methods: {
		addNumber(number, time) {
			let ob = new IntersectionObserver((entries) => {
				if (entries[0].intersectionRatio > 0) {
					let timer = setInterval(() => {
						if (this.number >= this.max) {

							clearInterval(timer);
							timer = null;
						}
						else {
							this.number += this.max / 20;
						}
					}, time);
				}
				else {
					this.number = 0
					this.transition = ''
				}
			})
			ob.observe(number)
		}
	}
}
</script>
<style lang="less" scoped>
.number-box {
	width: 150px;
	height: 88px;
	display: block;
}
#number {
	width: 100%;
	font-size: 36px;
	font-family: MicrosoftYaHei-Bold, Microsoft YaHei, -apple-system;
	font-weight: bold;
	color:  #2e6bf6;
}
.title {
	margin-top: 30px;
	width: 100%;
	font-size: 16px;
	font-family: -apple-system, Microsoft YaHei;
	color: @font-color;
}
</style>