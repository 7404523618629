<template>
	<section class="section section_3">
		<sub-title-2 class="section_3-title">平台架构</sub-title-2>
		<div class="sub-title">
			<div>是企业设备连接的物联网平台，</div>
			<div>可将设备数据流转到各自应用系统，是构建物联网应用的基础</div>
		</div>
		<div class="content">
			<img src="../assets/img/section_3_bg.png" />
		</div>
	</section>
</template>
<script>
import SubTitle2 from './SubTitle2.vue'
export default {
	components: { SubTitle2 },
}
</script>
<style lang="less" scoped>
.section_3-title {
	margin: 0 auto;
	margin-bottom: 40px;
}
.section_3 {
	padding-bottom: 58px;
	text-align: center;
	background: #f9fbff url("../assets/img/bg_7.png") no-repeat right 144px;

	.sub-title {
		font-size: 16px;
		color: #999999;
		margin-bottom: 48px;
	}
	.sub-title > div {
		font-size: 16px;
		color: #999999;
	}
	.content {
		width: 1200px;
		height: 522px;
		padding: 20px;
		box-sizing: border-box;
		margin: 0 auto;
		background: #fff;
		box-shadow: 0px 0px 10px rgba(0, 59, 140, 0.1);
		img {
			width: 100%;
			height: 100%;
		}
	}
}
</style>