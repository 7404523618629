<template>
	<div>
		<div class="title-box">
			<div class="title-font title">生态合作计划</div>
			<div class="title-describe title-describe-font">
				不管您是硬件厂商，还是行业领域系统公司，我们都诚邀您一起共建物联网
				生态，共同为客户提供更专业的服务，助力业务快速增长
			</div>
		</div>
		<div class="part1">
			<sub-title-2 class="part1-title">为何加入生态伙伴？</sub-title-2>
			<ul>
				<li>
					<circle-box class="part1-circle-box" :content="part1List[0]">
						<i class="ri-hand-coin-line"></i>
					</circle-box>
					<div class="part1-box"></div>
				</li>
				<li>
					<circle-box class="part1-circle-box" :content="part1List[1]">
						<i class="ri-road-map-line"></i>
					</circle-box>
					<div class="part1-box"></div>
				</li>
				<li>
					<circle-box class="part1-circle-box" :content="part1List[2]">
						<i class="ri-service-line"></i>
					</circle-box>
					<div class="part1-box"></div>
				</li>
			</ul>
		</div>
		<div class="part2">
			<div class="number">
				<div class="number-b">
					<number :max="20" :title="'物联网从业经验'">年</number>
					<number :max="500" :title="'接入智能化设备'">万+</number>
					<number :max="100" :title="'软著与专利'">+</number>
					<number :max="500" :title="'客户与项目'">+</number>
				</div>
			</div>
			<div id="wave"></div>
		</div>
		<div class="part3">
			<sub-title-2 class="part3-title">招募对象</sub-title-2>
			<ul>
				<li>
					<div class="part3-box">
						<div class="part3-box-title">硬件设备厂商</div>
						<ul>
							<li>模组厂商</li>
							<li>智能设备厂商</li>
							<li>传统设备厂商</li>
						</ul>
					</div>
				</li>
				<li>
					<div class="part3-box">
						<div class="part3-box-title">数字孪生可视化公司</div>
						<ul>
							<li>CIM</li>
							<li>BIM</li>
							<li>3D</li>
						</ul>
					</div>
				</li>
				<li>
					<div class="part3-box">
						<div class="part3-box-title">行业业务系统公司</div>
						<ul>
							<li>AI算法</li>
							<li>大数据分析</li>
							<li>垂直应用</li>
						</ul>
					</div>
				</li>
				<li>
					<div class="part3-box">
						<div class="part3-box-title">系统集成商</div>
						<ul>
							<li>智慧园区</li>
							<li>智慧医院</li>
							<li>智慧工地</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
		<x-footer-2 :title="'立刻成为生态伙伴'" :describe="'期待与您携手共创万物互联时代！'"></x-footer-2>
	</div>
</template>

<script>
import CircleBox from '../../components/CircleBox.vue'
import SubTitle2 from '../../components/SubTitle2.vue'
import WhiteButton from '../../components/WhiteButton.vue'
import * as THREE from 'three'
import Number from '../../components/Number.vue'
import XFooter2 from '../../components/xFooter2.vue'
export default {
	components: { WhiteButton, SubTitle2, CircleBox, Number, XFooter2 },
	mounted() {
		this.af = null;
		this.renderer = null;
		this.camera = null;
		this.scene = null;
		this.particles = null;
		this.particle = null;
		this.windowHalfY = window.innerHeight / 2
		this.windowHalfX = window.innerWidth / 2
		this.init();
		this.animate();
	},
	beforeDestroy() {
		this.renderer = null;
		this.camera = null;
		this.scene = null;
		this.particles = null;
		this.particle = null;
		cancelAnimationFrame(this.af);
	},
	data() {
		return {
			SEPARATION: 100, AMOUNTX: 50, AMOUNTY: 50,
			container: '',
			count: 0,

			mouseX: 0, mouseY: 0,

			windowHalfX: 0,
			windowHalfY: 0,
			part1List: [
				{
					title: '技术赋能',
					describe: '专注各自领域，共同为客户提供\n更专业的服务'
				},
				{
					title: '联合营销',
					describe: '母公司-利尔达集团全国拥有20多个办事处，\n可协助合作伙伴共同开展市场活动'
				},
				{
					title: '商机合作',
					describe: '商机信息及时共享，助力双方业务快速增长'
				},
			]
		}
	},
	methods: {
		init() {

			this.container = document.getElementById('wave')

			this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
			this.camera.position.z = 1000;

			this.scene = new THREE.Scene();

			this.particles = new Array();
			var PI2 = Math.PI * 2;
			var material = new THREE.ParticleCanvasMaterial({
				color: 0xFFFFFF,
				program: function (context) {
					context.beginPath();
					context.arc(0, 0, 1, 0, PI2, true);
					context.fill();
				}
			});

			var i = 0;

			for (var ix = 0; ix < this.AMOUNTX; ix++) {

				for (var iy = 0; iy < this.AMOUNTY; iy++) {

					this.particle = this.particles[i++] = new THREE.Particle(material);
					this.particle.position.x = ix * this.SEPARATION - ((this.AMOUNTX * this.SEPARATION) / 2);
					this.particle.position.z = iy * this.SEPARATION - ((this.AMOUNTY * this.SEPARATION) / 2);
					this.scene.add(this.particle);

				}

			}

			this.renderer = new THREE.CanvasRenderer();
			this.renderer.setSize(window.innerWidth, 500);
			this.renderer.setClearColorHex(0xffffff, 0);
			this.container.appendChild(this.renderer.domElement);
			document.addEventListener('mousemove', this.onDocumentMouseMove, false);
			document.addEventListener('touchstart', this.onDocumentTouchStart, false);
			document.addEventListener('touchmove', this.onDocumentTouchMove, false);

			//

			window.addEventListener('resize', this.onWindowResize, false);

		},

		onWindowResize() {

			this.windowHalfX = window.innerWidth / 2;
			this.windowHalfY = window.innerHeight / 2;

			this.camera.aspect = window.innerWidth / window.innerHeight;
			this.camera.updateProjectionMatrix();

			this.renderer.setSize(window.innerWidth, 500);

		},

		//

		onDocumentMouseMove(event) {

			this.mouseX = event.clientX - this.windowHalfX;
			// mouseY = event.clientY - windowHalfY;
			this.mouseY = -248.5;

		},

		onDocumentTouchStart(event) {

			if (event.touches.length === 1) {

				event.preventDefault();

				this.mouseX = event.touches[0].pageX - this.windowHalfX;
				this.mouseY = event.touches[0].pageY - this.windowHalfY;

			}

		},

		onDocumentTouchMove(event) {

			if (event.touches.length === 1) {

				event.preventDefault();

				this.mouseX = event.touches[0].pageX - this.windowHalfX;
				this.mouseY = event.touches[0].pageY - this.windowHalfY;

			}

		},

		//
		animate() {

			this.af = requestAnimationFrame(this.animate);

			this.render();


		},

		render() {

			this.camera.position.x += (this.mouseX - this.camera.position.x) * .05;
			this.camera.position.y += (- this.mouseY - this.camera.position.y) * .05;
			this.camera.lookAt(this.scene.position);

			var i = 0;

			for (var ix = 0; ix < this.AMOUNTX; ix++) {

				for (var iy = 0; iy < this.AMOUNTY; iy++) {

					this.particle = this.particles[i++];
					this.particle.position.y = (Math.sin((ix + this.count) * 0.3) * 50) + (Math.sin((iy + this.count) * 0.5) * 50);
					this.particle.scale.x = this.particle.scale.y = (Math.sin((ix + this.count) * 0.3) + 1) * 2 + (Math.sin((iy + this.count) * 0.5) + 1) * 2;

				}

			}

			this.renderer.render(this.scene, this.camera);

			this.count += 0.1;

		}
	}
}
</script>
<style lang="less" scoped>
.title-box {
	height: 504px;
	width: 100%;
	background: url("../../assets/img/banner_sthz.jpg") no-repeat center top;
	background-size: cover;
	text-align: center;
	padding-top: 214px;
	box-sizing: border-box;
	.title {
	}
	.title-describe {
		width: 594px;
		height: 54px;
		font-size: 18px;
		font-family: -apple-system, Microsoft YaHei;
		color: #ffffff;
		line-height: 27px;
		margin: 40px auto 67px;
		text-align: center;
	}
}
.part1 {
	max-width: @max-width;
	width: 100%;
	min-width: @min-width;
	margin: 0 auto;
	.part1-title {
		margin-top: 90px;
	}
	ul {
		margin-top: 60px;
		display: flex;
		justify-content: space-between;
		li {
			.part1-circle-box {
				white-space: pre;
				pointer-events: none;
			}
			position: relative;
			.part1-box {
				transition: 0.2s all linear;
				width: 387px;
				height: 238px;
				background: #ffffff;
				border: 1px solid #eaf0fe;
				position: absolute;
				bottom: -57px;
				z-index: -1;
			}
			&:hover {
				.part1-box {
					box-shadow: 3px 3px 10px 0px rgba(46, 107, 246, 0.14);
				}
			}
		}
	}
}
.part2 {
	height: 300px;
	margin-top: 147px;
	position: relative;
	overflow: hidden;
	#wave {
		position: absolute;
		bottom: 0;
		height: 300px;
		width: 100%;
		z-index: -1;
		background: #1e2124;
	}
	.number {
		width: 100%;
		height: 300px;
		margin: 0 auto;
		position: absolute;
		.number-b {
			align-items: center;
			display: flex;
			height: 100%;
			max-width: @max-width;
			width: 100%;
			min-width: @min-width;
			justify-content: space-between;
			margin: 0 auto;
		}
	}
}
.part3 {
	.part3-title {
		margin-top: 90px;
	}
	> ul {
		margin: 0 auto;
		margin-bottom: 90px;
		margin-top: 60px;
		display: flex;
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;

		justify-content: space-between;
		> li {
			transition: 0.2s all linear;
			&:hover {
				box-shadow: 3px 3px 10px 0px rgba(46, 107, 246, 0.14);
				.part3-box-title {
					background: @main-color;
				}
			}
		}
		.part3-box-title {
			width: 285px;
			height: 44px;
			background: #777f90;
			font-size: 16px;
			font-family: -apple-system, Microsoft YaHei;
			color: #ffffff;
			line-height: 44px;
		}
		.part3-box {
			width: 285px;
			height: 260px;
			border: 1px solid #eaf0fe;
			background: url("../../assets/img/sthz_bg2.png") no-repeat;
			background-position-y: 100%;
			ul {
				margin-top: 19px;
				li {
					width: 230px;
					font-size: 14px;
					font-family: -apple-system, Microsoft YaHei;
					color: #777f90;
					line-height: 39px;
					height: 39px;
					margin: 0 auto;
					border-bottom: 1px dashed #d5e1fd;
				}
				li:nth-child(3) {
					border: none;
				}
			}
		}
	}
}
</style>