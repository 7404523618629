<template>
	<div>
		<title-h :title='titleBox'></title-h>
		<div class="part1">
			<div class="introduction">
				<sub-title-1 class="introduction-title">产品介绍</sub-title-1>
				<div class="sub-title-describe-font introduction-title-describe">
					4G智能工业路由器采用成熟的MTK（联发科）系列芯片，主频高580MHz，内部设计1G以上存储空间，拥有RS485和RS232串口，可同时实现串口数据到4G和以太网网络。开发者可使用JS语言解析任意自定义协议数据（支持ModBus协议）实现快速开发并可远程升级，加速非IoT设备数据采集和远程控制。
				</div>
				<div class="list">
					<ul>
						<li
							v-for="(item,index) in li"
							:key="index"
						>
							<div class="pointer"></div>
							<div class="li-text">{{item}}</div>
						</li>
					</ul>
				</div>
				<img
					src="../../assets/img/lyq_img1.png"
					alt=""
				>
			</div>
			<sub-title-2 class="advantage">产品优势</sub-title-2>
			<div class="advantage-list">
				<div class="advantage-li">
					<div class="advantage-box">
						<img
							src="../../assets/img/lyq_ys1.png"
							alt=""
						>
						<div class="li-title sub-title-2-font">
							支持多种通信方式
						</div>
						<div class="sub-title-describe-font li-content">
							支持RS485、RS232、以太网等有线数据传输；
							以及Wi-Fi、4G等无线数据传输</div>
					</div>
				</div>
				<div class="advantage-li">
					<div class="advantage-box">
						<img
							src="../../assets/img/lyq_ys2.png"
							alt=""
						>
						<div class="sub-title-2-font li-title">快速解析自定义协议</div>
						<div class="sub-title-describe-font li-content">
							使用JavaScript解析任意自定义协议数据，
							降低传统开发时间</div>
					</div>
				</div>

				<div class="advantage-li">
					<div class="advantage-box">
						<img
							src="../../assets/img/lyq_ys3.png"
							alt=""
						>
						<div class="sub-title-2-font li-title">灵活部署与维护</div>
						<div class="sub-title-describe-font li-content">
							内置web界面，支持配置参数，无缝对接物联网全连接云平台</div>
					</div>
				</div>
			</div>
		</div>
		<div class="part2">
			<div class="transmission sub-title-font">
				传输方式
			</div>
			<img
				src="../../assets/img/title_center_blue.png"
				alt=""
			>
			<div class="transmission-box">
				<img
					src="../../assets/img/csfs.png"
					alt=""
				>
			</div>
		</div>
		<div class="part3">
			<sub-title-2 class="scenario">应用场景</sub-title-2>
			<div class="scenario-list">
				<div class="scenario-li">
					<div class="li-img">
						<img
							src="../../assets/img/lyq_pho1.png"
							alt=""
						>
					</div>
					<div class="scenario-li-title">智慧建筑检测</div>
				</div>
				<div class="scenario-li">
					<div class="li-img">
						<img
							src="../../assets/img/lyq_pho2.png"
							alt=""
						>
					</div>
					<div class="scenario-li-title">矿区数据采集</div>
				</div>
				<div class="scenario-li">
					<div class="li-img">
						<img
							src="../../assets/img/lyq_pho3.png"
							alt=""
						>
					</div>
					<div class="scenario-li-title">工业物联网</div>
				</div>
			</div>
		</div>
		<x-footer-2
			:title="'是否对我们的产品感兴趣了呢？'"
			:describe="'期待与您合作！'"
		></x-footer-2>
	</div>
</template>
<script>
import SubTitle1 from '../../components/SubTitle1.vue'
import SubTitle2 from '../../components/SubTitle2.vue'
import TitleH from '../../components/TitleH.vue'
import WhiteButton from '../../components/WhiteButton.vue'
import '../../assets/css/main.less'
import XFooter2 from '../../components/xFooter2.vue'
export default {
	components: { WhiteButton, SubTitle1, SubTitle2, TitleH,  XFooter2 },
	data() {
		return {
			/* 页面标题 */
			titleBox: {
				title: '智能工业路由器',
				describe: '利用无线网络为M2M行业提供无线数据传输功能， 无缝对接物联网全连接云平台，解决非IoT设备数据采集和远程控制。',
				img: require('../../assets/img/router.gif'),
				background: require('../../assets/img/banner_lyq.jpg')
			},
			li: ['自带精简版Linux系统（基于OpenWrt）', 'JS脚本快速开发解析协议', '内部自带1G以上存储', '双串口，双USB', '4G无线上网+双频Wi-Fi 300Mbps']
		}
	}
}
</script>
<style lang="less" scoped>
.part1 {
	max-width: @max-width;
	width: 100%;
	min-width: @min-width;
	margin: 0 auto;
	text-align: center;
	.introduction {
		margin-top: 90px;
		height: 480px;
		width: 100%;
		text-align: left;
		position: relative;
		background: url(../../assets/img/lyq_bg1.jpg);
		.introduction-title {
			margin: 40px 0 0 40px;
		}
		.introduction-title-describe {
			width: 804px;
			height: 84px;
			margin: 40px 0 0 40px;
		}
		img {
			position: absolute;
			right: 46px;
			top: 48px;
		}
		.list {
			width: 600px;
			height: 121px;
			margin: 60px 0 0 54px;
			text-align: left;
			ul {
				display: flex;
				justify-content: left;
				flex-wrap: wrap;
			}
			li {
				display: flex;
				align-items: center;
				margin: 0 30px 32px 0;
			}
			.pointer {
				width: 6px;
				height: 6px;
				background: #0f1d2f;
				border-radius: 50%;
				margin-right: 10px;
			}
			.li-text {
				width: 251px;
				height: 19px;
				font-size: 14px;
				color: #0f1d2f;
				line-height: 19px;
			}
		}
	}
	.advantage {
		margin-top: 90px;
	}
	.advantage-list {
		width: 100%;
		height: 260px;
		display: flex;
		justify-content: space-between;
		margin: 60px 0;
		.advantage-li {
			width: 387px;
			height: 260px;
			background: #ffffff;
			box-shadow: 3px 3px 10px 0px rgba(46, 107, 246, 0.14);
			border-radius: 8px;
			transition: 1s all linear;
			overflow: hidden;
			position: relative;
			.advantage-box {
				.li-title {
					transition: all 0.4s cubic-bezier(0, 1.04, 0.39, 0.89);
				}
				.li-content {
					transition: opacity 0.1s cubic-bezier(0.87, 0, 0.13, 1);
				}
			}
			img {
				margin-top: 24px;
				width: 160px;
				height: 160px;
				transition: all 0.4s cubic-bezier(0, 1.04, 0.39, 0.89);
			}
			&:hover {
				box-shadow: 0px 8px 16px 0px rgba(46, 107, 246, 0.2);
				.advantage-box {
					.li-title {
						color: #2e6bf6;
						margin-top: 19px;
					}
					.li-content {
						opacity: 1;
					}
					img {
						width: 120px;
						height: 120px;
						margin-top: 12px;
					}
				}
			}
			.li-title-1 {
				width: 128px;
				height: 21px;
				font-size: 16px;
				margin: 40px auto 0;
				font-family: MicrosoftYaHei-Bold, Microsoft YaHei,-apple-system;
				font-weight: bold;
				color: #2e6bf6;
				line-height: 21px;
			}
			.li-title {
				margin-top: 35px;
			}
			.li-content {
				position: absolute;
				left: 40px;
				bottom: 20px;
				text-align: center;
				width: 308px;
				height: 48px;
				opacity: 0;
				line-height: 24px;
			}
		}
	}
}
.part2 {
	height: 659px;
	background: url(../../assets/img/lyq_bg2.jpg);
	padding-top: 60px;
	img {
		margin-top: 20px;
	}
	.transmission-box {
		height: 430px;
		width: 100%;
		margin-top: 40px;
		img {
			max-width: @max-width;
			width: 100%;
			min-width: @min-width;
			height: 430px;
		}
	}
}
.part3 {
	overflow: hidden;
	.scenario {
		margin-top: 60px;
	}
	.scenario-list {
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		display: flex;
		justify-content: space-between;
		margin: 40px auto 90px;

		img {
			transition: 0.2s all linear;
			backface-visibility: hidden;
		}
		img:hover {
			transform: scale(1.05);
		}
		.li-img {
			width: 387px;
			height: 260px;
			overflow: hidden;
		}
		.li-content {
			backface-visibility: hidden;
		}
		.scenario-li-title {
			margin: 0 auto;
			width: 96px;
			height: 21px;
			font-size: 16px;
			color: #0f1d2f;
			line-height: 21px;
			margin-top: 20px;
		}
	}
}
</style>