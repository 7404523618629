<template>
  <div class="bar-bottom">
    <div class="bar-bottom-title">
      <slot name="title"></slot>
    </div>
    <div class="bar-bottom-desc">
      <slot name="desc"></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.bar-bottom {
  padding: 30px 0;
  background-size: 100% 100%;
  background-image: url("../../assets/img/bottom_img_m.jpg");
  .bar-bottom-title {
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
  }
  .bar-bottom-desc {
    font-size: 17px;
    margin-top: 10px;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
    letter-spacing: 3px;
  }
}
</style>