<template>
	<div class="circle">
		<div class="icon">
			<slot></slot>
		</div>
		<div class="sub-title-2-font circle-title">{{content.title}}</div>
		<div class="sub-title-describe-font circle-describe">{{content.describe}}</div>
	</div>
</template>

<script>
export default {
	props: ['content']
}
</script>

<style lang="less" scoped>
.circle {
	width: 280px;
	margin: 0 50px;
	height: 243px;
	text-align: center;
	&:hover {
		color: @main-color;
		.icon {
			border: 1px solid #2e6bf6;
			line-height: 119px;
			box-sizing: border-box;
		}
		.circle-title {
			color: @main-color;
		}
		.circle-describe {
			color: @main-color;
		}
	}
	.icon {
		
		width: 120px;
		height: 120px;
		border-radius: 50%;
		background: #ffffff;
		box-shadow: 3px 3px 10px 0px rgba(46, 107, 246, 0.14);
		margin: 0 auto;
		font-size: 50px;
		color: @main-color;
		line-height: 120px;
	}
	.circle-title {
		margin-top: 40px;
		cursor: pointer;
	}
	.circle-describe {
		margin-top: 18px;
		line-height: 22px;
		cursor: pointer;
	}
}
</style>