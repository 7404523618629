<template>
	<footer>
		<div class="article">
			<div>{{ title }}</div>
			<div>{{ describe }}</div>
			<button @click="link">{{ btn }}</button>
		</div>
		<div class="content">
			<div class="left">
				<ul class="nav">
					<li>
						<div><a @click.prevent="push('/', '首页')">首页</a></div>
					</li>
					<li>
						<div><a href="javascript:;">产品</a></div>
					</li>
					<li>
						<div><a href="javascript:;" @click="push('https://help.senthink.com', '文档中心', true)">文档中心</a></div>
					</li>
					<li>
						<div><a @click.prevent="push('/eCooperation', '生态合作')">生态合作</a></div>
					</li>
					<li>
						<div><a @click.prevent="push('/about', '关于我们')">关于我们</a></div>
					</li>
				</ul>
				<ul class="product">
					<li><a @click.prevent="push('/iot', '物联网全连接云平台')">物联网全连接云平台</a></li>
					<li><a @click.prevent="push('/router', '智能工业路由器')">智能工业路由器</a></li>
					<li><a @click.prevent="push('/dataVisualization', '数据可视化')">数据可视化</a></li>
				</ul>
				<ul class="link">
					<li class="link-title">友情链接</li>
					<li><a href="javascript:;" @click="push('https://www.lierda.com/', '利尔达集团', true)">利尔达集团</a></li>
					<li><a href="javascript:;" @click="push('https://www.senthink.com', '贤芯科技', true)">贤芯科技</a></li>
					<li><a href="javascript:;" @click="push('https://www.simlink.tech/', '芯智行', true)">芯智行</a></li>
				</ul>
			</div>
			<div class="right">
				<ul>
					<li>杭州贤芯科技有限公司</li>
					<li>地址：杭州市文一西路1326号利尔达科技园1号楼</li>
					<li>电话：18167172990</li>
					<li>邮箱：support@senthink.com</li>
					<li><img src="../assets/img/ewm.png" alt=""><img src="../assets/img/ewm2.png" alt=""></li>
					<li style="margin-top: 20px;">利尔达公众号&nbsp;&nbsp;&nbsp;更多信息？扫码了解</li>
				</ul>
			</div>
			<div class="copyright">© CopyRight 2015-2022, SENTHINK.COM, Inc.All Rights Reserved. <a
					href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备15040917号</a> </div>
		</div>
	</footer>
</template>
<script>
export default {
	props: {
		title: {
			require: true,
			type: String,
		},
		btn: {
			require: true,
			type: String,
		},
		describe: {
			require: true,
			type: String,
		},
		href: {
			require: false,
			type: String
		}
	},
	methods: {
		link() {
			const { push } = window?._czc || {};
			const hasPush = push && typeof push === 'function';
			if (this.href) {
				window.location.href = this.href;
				hasPush && push(['_trackEvent', this.title, '了解详情', this.href]);
			}
			else {
				this.$router.push('/eCooperation')
				hasPush && push(['_trackEvent', '生态合作', '了解详情', '/eCooperation']);
			}
		},
		push (url, name, extra=false) {
			if (window?.location?.pathname === url) {
				return;
			}
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '底部', url]);
			}
			if (extra) {
				window.location.href = url;
			} else {
				this.$router.push(url);
			}
		}
	}
}
</script>
<style lang="less" scoped>
footer {
	width: 100%;
	height: 770px;
	background: #16171b;

	.article {
		height: 280px;
		background: url("../assets/img/bottom_img.jpg") no-repeat center top;
		background-size: cover;

		:first-child {
			padding-top: 49px;
			font-size: 28px;
			color: @font-color;
		}

		:nth-child(2) {
			padding-top: 20px;
			font-size: 34px;
			font-family: -apple-system, Microsoft YaHei;
			font-weight: bold;
			color: @font-color;
			line-height: 45px;
			letter-spacing: 6px;
		}

		:nth-child(3) {
			width: 144px;
			height: 40px;
			background: @mainBack-color;
			border-radius: 20px;
			border: none;
			font-size: 16px;
			cursor: pointer;
			font-family: -apple-system, Microsoft YaHei;
			color: @main-color;
			margin-top: 40px;
			line-height: 40px;
			transition: 0.2s all linear;

			&:hover {
				box-shadow: 3px 3px 16px 0px rgba(22, 23, 27, 0.13);
			}
		}
	}

	.content {
		display: flex;
		position: relative;
		justify-content: space-between;
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		height: 490px;
		margin: 0 auto;

		.left {
			padding-top: 80px;

			.nav {
				display: flex;
				justify-content: space-between;
				white-space: nowrap;

				li {
					margin-right: 90px;
					font-size: 16px;
					font-family: -apple-system, Microsoft YaHei;
					font-weight: bold;
					color: @font-color;

					a {
						cursor: pointer;
					}
				}
			}

			.product {
				margin-top: 37px;
				color: @font-color;
				font-size: 14px;
				opacity: 0.6;
				text-align: left;
				margin-left: 118px;

				li {
					height: 28px;
					line-height: 28px;

					a {
						cursor: pointer;
					}
				}
			}

			.link {
				position: relative;
				margin-top: 65px;
				display: flex;
				color: @font-color;
				font-size: 14px;
				text-align: left;
				font-family: -apple-system, Microsoft YaHei;

				li {
					opacity: 0.6;
					line-height: 19px;
				}

				:nth-child(2) {
					position: absolute;
					left: 118px;
				}

				:nth-child(3) {
					position: absolute;
					left: 236px;
				}

				:nth-child(4) {
					position: absolute;
					left: 342px;
				}

				.link-title {
					font-size: 16px;
					opacity: 1;
					font-family: -apple-system, Microsoft YaHei;
					font-weight: bold;
				}
			}
		}

		.right {
			padding-top: 80px;
			text-align: left;

			ul {
				li {
					font-size: 14px;
					font-family: -apple-system, Microsoft YaHei;
					color: @font-color;
					line-height: 28px;
					opacity: 0.6;
					height: 28px;
				}

				:first-child {
					font-size: 16px;
					height: 21px;
					opacity: 1;
					font-family: MicrosoftYaHei-Bold, -apple-system, Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
					line-height: 21px;
					margin-bottom: 30px;
				}

				:nth-child(5) {
					width: 200px;
					height: 80px;
					opacity: 1;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					img {
						display: inline-block;
						width: 80px;
						height: 100%;
					}
				}
			}
		}

		.copyright {
			position: absolute;
			bottom: 0;
			height: 55px;
			max-width: @max-width;
			width: 100%;
			min-width: @min-width;
			text-align: center;
			color: @font-color;
			font-size: 14px;
			line-height: 55px;
			border-top: 1px solid rgba(255, 255, 255, 0.3);
		}
	}
}
</style>