<template>
	<div>
		<div class="content">
			<title-h :title="titleBox" :describeStyle="describeStyle"></title-h>
			<section-1></section-1>
			<section-2></section-2>
			<section-3></section-3>
			<section-4></section-4>
			<section-5></section-5>
			<section-6></section-6>
			<section-7></section-7>
		</div>
		<x-footer-2 :title="'是否对我们的产品感兴趣了呢？'" :describe="'期待与您合作！'"></x-footer-2>
	</div>
</template>
<script>

import TitleH from '../../components/TitleH.vue'
import '../../assets/css/icon.css'
import Section1 from '../../components/Section1.vue'
import Section2 from '../../components/Section2.vue'
import Section3 from '../../components/Section3.vue'
import Section4 from '../../components/Section4.vue'
import Section5 from '../../components/Section5.vue'
import Section6 from '../../components/Section6.vue'
import Section7 from '../../components/Section7.vue'
import XFooter2 from '../../components/xFooter2.vue'
export default {
	components: { TitleH, Section1, Section2, Section3, Section4, Section5, Section6, Section7, XFooter2 },
	data() {
		return {
			titleBox: {
				title: '物联网全连接云平台 ',
				describe: '为设备提供安全可靠的连接管理能力，整合物联网产业链上下游，向下连接智能设备，向上承接物联网应用，形成端到云完整链路物联网解决方案，赋能企业各类IOT场景接入',
				img: require('../../assets/img/iot.gif'),
				background: require('../../assets/img/banner_iot.jpg')
			},
			describeStyle: {
				width: '544px'
			}
		}
	},
	mounted() {
		this.$init()
	},
	methods: {
		$init() {
			const list = document.getElementsByClassName('icon_hover');
			for (let i = 0; i < list.length; i++) {
				let count = 21;
				let element = list[i];
				let ing = false;
				element.onmouseover = function () {
					const _this = this;
					ing = 'out';
					let over = setInterval(function () {
						count--;
						if (count > 0 && ing === 'out') {
							_this.getElementsByClassName('icon_title')[0].style.backgroundPositionY = count * 92 + 'px';
						} else {
							clearInterval(over);
						}
					}, 41);
				}
				element.onmouseout = function () {
					const _this = this;
					ing = 'over';
					let over = setInterval(function () {
						count++;
						if (count < 21 && ing === 'over') {
							_this.getElementsByClassName('icon_title')[0].style.backgroundPositionY = count * 92 + 'px';
						} else {
							clearInterval(over);
						}
					}, 41);
				}
			}
		}
	}
}
</script>
<style lang="less" scoped>
.content {
	min-width: @min-width;
	overflow: hidden;
}
</style>