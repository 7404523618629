<template>
	<div class="detail" >
		<a href="javascript:;">了解详情</a>
		<img
			src="../assets/img/arrow_right_b.png"
			alt=""
		>
	</div>
</template>
<script>
export default {
	props: ['href']
}
</script>
<style scoped lang="less">
.detail {
	cursor: pointer;
	a {
		color: @main-color;
		height: 19px;
	}
	img {
		vertical-align: middle;
		transition: all 0.3s;
		height: 100%;
    width: 14px;
    height: 14px;
		margin-left: 6px;
	}
	display: inline-flex;
	align-items: center;
	&:hover {
		img {
			transform: translateX(5px);
		}
	}
}
</style>