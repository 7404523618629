<template>
	<div class="sub-title-1" >
		<div  class="sub-title-font" >
			<slot></slot>
		</div>
		<img src="../assets/img/title_right_blue.png" alt />
	</div>
</template>
<script>
export default {
}
</script>
<style lang="less" scoped>
.sub-title-1 {
	display: inline-flex;
	align-items: center;
	img {
		height: 100%;
		margin-left: 24px;
	}
}
</style>