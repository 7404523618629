import alert from "./$alert";
import ajax from "./ajax";
export default {

  jumpTo (href, name) {
    
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '导航', href]);
			}
    if (href.indexOf('http') > -1) {
      window.location.href = href;
    } else {
      window.location.href = 'https://account.xiot.senthink.com' + href;
    }
  },
  toShow(key) {
    const isLogin = key === 'console';
    const consoles = document.getElementsByClassName('console');
    const trials = document.getElementsByClassName('trial');
    this.toTrigger(consoles, isLogin);
    this.toTrigger(trials, !isLogin);
    if (document.getElementById('user'))
      document.getElementById('user').className = isLogin ? 'user' : 'user hide';
    if (document.getElementById('login'))
      document.getElementById('login').className = isLogin ? 'login hide' : 'login';
    if (document.getElementById('mLogout'))
      document.getElementById('mLogout').className = isLogin ? 'register-btn' : 'register-btn hide';
    if (document.getElementById('mLogin'))
      document.getElementById('mLogin').className = isLogin ? 'login-btn hide' : 'login-btn';
  },
  toTrigger(data, state) {
    if (state) {
      for (let i = 0; i < data.length; i++) {
        data[i].classList.remove('hide');
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        if (!data[i].classList.contains('hide')) {
          data[i].classList.add('hide');
        }
      }
    }

  },
  handleLogout() {
    if (!ajax.getItem('x-aep')) {
      alert('已退出');
    }
    ajax.ajax({ data: null, url: 'senthink/aep/backend/user/v1/auth/loginOut', method: 'POST', token: true }, (r) => {
      console.log('r', r);
      if (!r) {
        localStorage.clear();
        ajax.docCookies.removeItem('x-aep', '', 'xiot.senthink.com');
        alert('已退出');
        this.refreshState();
      } else {
        const msg = r && r.msg ? r.msg : '退出登录失败';
        alert(msg, 'error');
      }
    });
  },
  toLogin() {
    const current = window.location.href;
    window.location.href = 'http://account.xiot.senthink.com/login?from=' + current;
  },

  refreshState() {
    const data = ajax.getItem('x-aep');
    if (document.getElementById('userName')) {
      if (data && data.user && data.user.name) {
        document.getElementById('userName').innerHTML = data.user.name;
      } else {
        document.getElementById('userName').innerHTML = '';
      }
    }
    const state = data ? 'console' : 'trial';
    this.toShow(state);
  },
}