<template>
	<section class="section section_7">
		<article class="article1">
			<div class="section_7-title-1">
				<img
					class="left"
					src="../assets/img/title_left_wight.png"
					alt=""
				>
				<div>安全认证</div>
				<img
					class="right"
					src="../assets/img/title_right_wight.png"
					alt=""
				>
			</div>
			<div class="sub-title">安全、合规、可靠的物联网平台</div>
			<content>
				<div class="bg1">
					<div>信息安全管理体系认证</div>
				</div>
				<div class="bg2">
					<div>质量管理体系认证</div>
				</div>
				<div class="bg3">
					<div>软件能力成熟度认证</div>
				</div>
			</content>
		</article>
		<article class="article2">
			<sub-title-2 class="section_7-title-2">多种合作模式</sub-title-2>
			<div class="sub-title">助力客户快速落地</div>
			<content>
				<div>
					<div class="title"><img src="../assets/img/icon_7_1.png" />
						<div>公有托管</div>
					</div>
					<ul>
						<li>
							<div>低成本</div>
							<div>公有云按年收费，最低成本上云</div>
						</li>
						<li>
							<div>托管式服务</div>
							<div>使用公有云无需自己搭建服务器</div>
						</li>
					</ul>
				</div>
				<div>
					<div class="title"><img src="../assets/img/icon_7_2.png" />
						<div>私有部署</div>
					</div>
					<ul>
						<li>
							<div>私有数据</div>
							<div>部署自己的服务器上，数据、系统私有化</div>
						</li>
						<li>
							<div>个性化定制</div>
							<div>可根据不同的业务场景做个性化定制</div>
						</li>
					</ul>
				</div>
			</content>
		</article>
	</section>
</template>
<script>
import SubTitle2 from './SubTitle2.vue'
export default {
	components: { SubTitle2 },

}
</script>
<style lang="less" scoped>
.section {
	text-align: center;
	width: 100%;
}
.section .sub-title {
	font-size: 16px;
	color: #999999;
	margin-bottom: 76px;
}

.section .sub-title > div {
	font-size: 16px;
	color: #999999;
}
.section_7-title-2 {
	margin-top: 55px;
	margin-bottom: 40px;
}
.section_7 {
	width: 100%;
	margin-top: -368px;
	z-index: 10;
	background-size: 100% 300px;
}
.section_7 .article1 .section_7-title-1 {
	margin-top: 144px;
	padding-top: 0;
	margin-bottom: 30px;
	color: #fff;
	display: inline-flex;
  align-items: center;
	font-size: 28px;
	font-weight: 400;
	.left {
		height: 100%;
		margin-right: 20px;
	}
	.right {
		height: 100%;
		margin-left: 20px;
	}
}
.section_7 .article1 header,
.section_7 .article1 .sub-title {
	color: #fff;
}
.section_7 .article1 .sub-title {
	font-size: 16px;
	margin-bottom: 38px;
	opacity: 0.7;
}
.section_7 .article1 content {
	display: inline-flex;
	width: 1200px;
	height: 400px;
	background: #fff;
	position: relative;
	box-shadow: 0px 6px 20px 0px rgba(2, 109, 255, 0.1);
}
.section_7 .article1 content > div {
	flex: 1;
	background: url(../assets/img/section_7_1.png) no-repeat center 87px;
	display: flex;
	justify-content: center;
}
.section_7 .article1 content > div > div {
	font-size: 20px;
	color: #333333;
	position: absolute;
	bottom: 88px;
}
.section_7 .article1 content .bg2 {
	background-image: url("../assets/img/section_7_2.png");
}
.section_7 .article1 content .bg3 {
	background-image: url("../assets/img/section_7_3.png");
}
.section_7 .article2 {
	height: 548px;
}
.section_7 .article2 header {
	margin-bottom: 28px;
}
.section_7 .article2 .sub-title {
	margin-bottom: 76px;
}
.section_7 .article2 content {
	display: inline-flex;
	width: 1200px;
}
.section_7 .article2 content > div {
	flex: 1;
}
.section_7 .article2 content > div .title {
	height: 180px;
}
.section_7 .article2 content > div .title img {
	width: 86px;
	height: 68px;
	margin-bottom: 26px;
}
.section_7 .article2 content > div .title > div {
	color: #333333;
	font-size: 24px;
	height: 24px;
	margin-bottom: 64px;
}
.section_7 .article2 content > div ul {
	display: inline-flex;
	text-align: left;
	width: 440px;
}
.section_7 .article2 content > div ul li {
	flex: 1;
}
.section_7 .article2 content > div ul li div:first-of-type {
	font-size: 16px;
	color: #333333;
	line-height: 30px;
}
.section_7 .article2 content > div ul li div:last-of-type {
	font-size: 14px;
	color: #999999;
	line-height: 30px;
}
.section_7 .article2 content > div ul li + li {
	margin-left: 30px;
}
</style>