<template>
  <div>
    <div class="title-box">
      <div class="title-font title">关于我们</div>
      <div class="title-describe title-describe-font">
        利尔达科技集团旗下全资子公司，专业从事物联网系统研发，专注于设备管理智能化和物联网应用系统开发。
      </div>
    </div>
    <div class="part-1">
      <sub-title-2>公司介绍</sub-title-2>
      <div class="sub-title-describe-font about-desc">
        依托利尔达强大的智能硬件优势，整合资源，拥有严谨规范的项目立项、研发、测试运作流程，完备的系统部署运维体系。
        <br />贤芯科技致力于为用户提供整套完善稳健的物联网应用系统。
      </div>
      <div class="list">
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-user-settings-line"></i>
            </div>
            <div class="list-item-title-text">项目管理组</div>
          </div>
          <div class="list-item-desc">
            基于CMMI3过程能力的项目流程，多年大型物联网项目实施管理经验，能有效组织、领导、协调相关干系人各项工作，严格履行合同要求，为完成既定的质量、进度等目标而努力的项目管理团队。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-chat-check-line"></i>
            </div>
            <div class="list-item-title-text">产品组</div>
          </div>
          <div class="list-item-desc">深耕于物联网用、云、边、管、端各领域数年，为客户提供一站式IoT解决方案，涵盖：市场调研分析、需求沟通梳理、功能原型设计、产品全生命周期管理等。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-pencil-ruler-2-line"></i>
            </div>
            <div class="list-item-title-text">设计组</div>
          </div>
          <div class="list-item-desc">精通PS、AI、Sketch、AE等设计软件，兼具平面、UI、动画等设计领域，具备扎实的设计基础，多年设计经验及团队协作能力，能快速响应客户需求。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-terminal-box-line"></i>
            </div>
            <div class="list-item-title-text">研发组（前端、后端、移动端）</div>
          </div>
          <div class="list-item-desc">
            拥有完善、成熟的管理和开发流程，集架构思维、设计执行、服务意识与一体，汇聚物联网领域的技术精英，对物联网行业的发展和趋势有着深刻的认识和理解，有着共同目标默契协作的研发团队。</div>
        </div>
        <div class="list-item">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-user-search-line"></i>
            </div>
            <div class="list-item-title-text">测试组</div>
          </div>
          <div class="list-item-desc">保障系统业务高质量交付，疑难问题高效率解决，用户体验高品质提升，化身千万用户，致力于成为测试服务领域里最被信任的探索者。</div>
        </div>
        <div class="list-item no-border">
          <div class="list-item-title">
            <div class="list-item-icon">
              <i class="ri-file-cloud-line"></i>
            </div>
            <div class="list-item-title-text">运维组</div>
          </div>
          <div class="list-item-desc">拥有丰富的Linux系统生态运维经验，同时专注于云原生，容器云，以及各大云平台SaaS云产品运维。提供项目上线，容量规划，运行保障，7*24响应等等运维服务。
          </div>
        </div>
      </div>
    </div>
    <div class="part-2">
      <div class="part-2-content">
        <sub-title-2>荣誉资质</sub-title-2>
        <div class="list-credentials">
          <div class="list-credentials-item" v-for="( item, index) in list" :key="index">
            <div class="dot"></div>
            <div class="item-text">{{ item }}</div>
          </div>
        </div>
        <img class="credentials-img" src="../../assets/img/ryzs.png" alt />
      </div>
    </div>
    <div class="part-3">
      <sub-title-2>合作伙伴</sub-title-2>
      <div class="swipe-box">
        <div class="arrow-left" @click="swipeToLeft"></div>
        <van-swipe class="my-swipe" :show-indicators="false" ref="swipe" :autoplay="5000">
          <van-swipe-item>
            <div class="swipe-img-part">
              <img class="img-top" src="../../assets/img/hzhb1.jpg" alt />
              <img class="img-top" src="../../assets/img/hzhb2.png" alt />
              <img class="img-top" src="../../assets/img/hzhb3.png" alt />
              <img class="img-top no-right" src="../../assets/img/hzhb4.png" alt />
              <img src="../../assets/img/hzhb5.png" alt />
              <img src="../../assets/img/hzhb6.png" alt />
              <img src="../../assets/img/hzhb7.png" alt />
              <img class="no-right" src="../../assets/img/hzhb8.png" alt />
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="swipe-img-part">
              <img class="img-top" src="../../assets/img/hzhb9.png" alt />
              <img class="img-top" src="../../assets/img/hzhb10.png" alt />
              <img class="img-top" src="../../assets/img/hzhb11.png" alt />
              <img class="img-top no-right" src="../../assets/img/hzhb12.png" alt />
              <img src="../../assets/img/hzhb13.png" alt />
              <img src="../../assets/img/hzhb14.png" alt />
              <img src="../../assets/img/hzhb15.png" alt />
              <img class="no-right" src="../../assets/img/hzhb16.png" alt />
            </div>
          </van-swipe-item>
        </van-swipe>
        <div class="arrow-right" @click="swipeToRight"></div>
      </div>
      <div class="part-4">
        <sub-title-2>联系我们</sub-title-2>
        <div class="contact-part">
          <img class="QR" src="../../assets/img/ewm.png" alt />
          <div class="QR-text">贤芯官方公众号</div>
          <div class="contact">
            <img class="map" src="../../assets/img/dt.png" alt />
            <div class="contact-message">
              <div class="city">
                <i class="ri-map-pin-2-fill"></i>
                <div class="city-name">浙江杭州</div>
              </div>
              <div class="contact-item">
                <div class="label">公司地址</div>
                <div class="item">杭州市文一西路1326号利尔达物联网科技园1号楼</div>
              </div>
              <div class="contact-item">
                <div class="label">公司邮箱</div>
                <div class="item">support@senthink.com</div>
              </div>
              <div class="contact-item">
                <div class="label">商务对接</div>
                <div class="item">王先生-18167172990</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <x-footer-2 :title="'立刻成为生态伙伴'" :describe="'期待与您携手共创万物互联时代！'"></x-footer-2>
  </div>
</template>

<script>
import SubTitle2 from '../../components/SubTitle2.vue'
import XFooter2 from '../../components/xFooter2.vue'
export default {
  components: { SubTitle2, XFooter2 },
  data() {
    return {
      list: ['杭州市高新技术企业', '杭州市雏鹰计划企业', '软件企业双软企业证书', '杭州市人工智能学会会员', '杭州市余杭区科技企业研发中心', '数据资源开发协会理事单位', '浙江省科技型中小企业证书', '浙江省软件行业协会理事单位', 'CMMI 3级认证/ISO9001/ 27001认证'],
    }
  },
  methods: {
    swipeToLeft() {
      this.$refs.swipe.prev()
    },
    swipeToRight() {
      this.$refs.swipe.next()
    }
  }
}
</script>

<style lang="less" scoped>
.title-box {
  height: 504px;
  width: 100%;
  background: url("../../assets/img/banner_gywm.png") no-repeat center top;
  background-size: cover;
  text-align: center;
  padding-top: 214px;
  box-sizing: border-box;

  .title {}

  .title-describe {
    width: 594px;
    height: 54px;
    font-size: 18px;
    font-family: -apple-system, Microsoft YaHei;
    color: #ffffff;
    line-height: 27px;
    margin: 40px auto 67px;
    text-align: center;
  }
}

.about-desc {
  margin-top: 40px;
}

.part-1 {
  max-width: @max-width;
  width: 100%;
  min-width: @min-width;
  margin: 0 auto;
  margin-top: 90px;
  margin-bottom: 90px;

  .list {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-row-gap: 40px;
    grid-column-gap: 20px;
    margin-top: 40px;
  }

  .list-item {
    text-align: left;
    width: 387px;
    height: 260px;
    background: #ffffff;
    box-shadow: 3px 3px 10px 0px rgba(46, 107, 246, 0.14);
    border-radius: 8px;
    transition: all 0.2s;
  }

  .list-item:hover {
    background: #2e6bf6;

    .list-item-title-text,
    .list-item-desc {
      color: #ffffff;
    }
  }

  .list-item-icon {
    background-color: #fff;
    text-align: center;
    width: 66px;
    height: 66px;
    border: 1px solid #2e6bf6;
    border-radius: 50%;
    font-size: 33px;
    line-height: 66px;
    color: #2e6bf6;
    margin-top: 20px;
    margin-left: 20px;
  }

  .list-item-title-text {
    font-size: 16px;
    font-weight: bold;
    color: #0f1d2f;
    line-height: 21px;
    margin-top: 30px;
    margin-left: 30px;
  }

  .list-item-desc {
    font-size: 14px;
    color: #777f90;
    line-height: 24px;
    text-align: justify;
    margin: 15px 30px 0 30px;
  }
}

.part-2 {
  padding-top: 60px;
  box-sizing: border-box;
  background-image: url("../../assets/img/gywm_bg1.jpg");
  width: 100%;
  height: 720px;
  background-size: cover;

  .part-2-content {
    max-width: @max-width;
    width: 100%;
    min-width: @min-width;
    margin: 0 auto;
  }

  .list-credentials {
    display: flex;
    flex-wrap: wrap;
    width: 1000px;
    height: 238px;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 40px;
  }

  .list-credentials-item {
    width: 320px;
    height: 66px;
    background: #ffffff;
    border: 1px solid #eaf0fe;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 6px;
    font-size: 15px;
    color: #16171b;
    line-height: 20px;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      box-shadow: 2px 2px 8px 0px rgba(46, 107, 246, 0.14);
      border-radius: 4px;

      .dot {
        background: #2e6bf6;
      }
    }

    .dot {
      transition: all 0.3s;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #d5e1fd;
      margin-right: 10px;
    }
  }

  .credentials-img {
    width: 1000px;
    height: 265px;
    margin-top: 40px;
  }
}

.part-3 {
  max-width: @max-width;
  width: 100%;
  min-width: @min-width;
  margin: 0 auto;
  margin-top: 90px;

  .swipe-box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 100px;
  }

  .my-swipe {
    cursor: auto;
    padding: 10px 0;
  }

  .arrow-left {
    width: 40px;
    height: 40px;
    background: #ffffff;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.3s;
    background-image: url("../../assets/img/arrow_left_n.png");

    &:hover {
      background-image: url("../../assets/img/arrow_left_s.png");
    }
  }

  .arrow-right {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: #ffffff;
    cursor: pointer;
    transition: all 0.3s;
    background-image: url("../../assets/img/arrow_right_n.png");

    &:hover {
      background-image: url("../../assets/img/arrow_right_s.png");
    }
  }

  .swipe-img-part {
    img {
      width: 240px;
      margin-right: 20px;
      height: 80px;
      background: #ffffff;
      box-shadow: 3px 3px 10px 0px rgba(46, 107, 246, 0.14);
      border-radius: 4px;
    }

    .img-top {
      margin-bottom: 40px;
    }

    .no-right {
      margin-right: 0;
    }
  }
}

.part-4 {
  max-width: @max-width;
  width: 100%;
  min-width: @min-width;
  margin-top: 100px;
  margin-bottom: 90px;

  .contact-part {
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    background: #fafbff;
    margin-top: 65px;

    .QR {
      width: 120px;
      height: 120px;
      background: #ffffff;
      box-shadow: 2px 2px 5px 0px rgba(46, 107, 246, 0.14);
      border-radius: 2px;
      position: absolute;
      top: 185px;
      right: 64px;
      z-index: 0;
    }

    .QR-text {
      font-size: 14px;
      color: #16171b;
      line-height: 19px;
      position: absolute;
      top: 321px;
      left: 1027px;
    }
  }

  .contact {
    text-align: left;
    position: relative;
    display: flex;

    .contact-message {
      margin-top: 43px;
    }

    .city {
      display: flex;
      align-items: center;
      padding-bottom: 5px;

      .ri-map-pin-2-fill {
        font-size: 40px;
        margin-right: 10px;
        color: rgb(46, 107, 246);
      }

      .city-name {
        font-size: 20px;
        font-weight: bold;
        color: #16171b;
        line-height: 26px;
      }
    }

    .contact-item {
      margin-top: 30px;

      .label {
        font-size: 14px;
        color: #16171b;
        line-height: 19px;
        margin-bottom: 10px;
      }

      .item {
        font-size: 14px;
        color: #16171b;
        line-height: 19px;
      }
    }

    .map {
      margin-right: 60px;
      width: 560px;
      height: 360px;
    }
  }
}
</style>