<template>
  <div class="dataVis">
    <m-banner :url="require('../../assets/img/banner_sjksh.png')">
      <template #title>数据可视化</template>
      <template #img>
        <img src="../../assets/img/sjksh.gif" alt />
      </template>
      <template #desc>为企业提供快速搭建实时数据大屏工具，零代码拖拽方式轻松搭建数据可视化大屏</template>
    </m-banner>
    <div class="zero-code">
      <m-sub-title-2 class="sub-title2">零代码拖拽，快速搭建</m-sub-title-2>
      <div class="list">
        <div class="list-item">
          <i class="ri-drag-drop-line item-icon"></i>
          <div class="item-text">
            <div class="item-title">拖拽式编辑界面</div>
            <div class="item-desc">免编程快速开发大屏，图表数据直观展示</div>
          </div>
        </div>
        <div class="list-item">
          <i class="ri-flow-chart item-icon"></i>
          <div class="item-text">
            <div class="item-title">丰富的数据组件</div>
            <div class="item-desc">开放组件基础配置项，可自定义组件样式</div>
          </div>
        </div>
        <div class="list-item no-border">
          <i class="ri-archive-line item-icon"></i>
          <div class="item-text">
            <div class="item-title">多种数据源对接</div>
            <div class="item-desc">
              静态、API、数据库自定义接入无缝对接物联网
              全连接云平台
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base">
      <div class="base-bg">
        <div class="base-title">基础模板，一键使用</div>
        <div class="base-desc">
          提供持续丰富的大屏模版不断满足各行业展示需求，可在
          模版基础之上快速修改生成企业专属大屏
        </div>
        <img src="../../assets/img/title_big_wight.png" alt />
      </div>
    </div>
    <div class="publish">
      <div class="publish-title">灵活部署与发布</div>
      <div class="publish-desc">一键生成HTML分析链接，支持加密发布，可通过PC、Ipad、手机浏览器访问</div>
      <div class="publish-img">
        <img src="../../assets/img/ksh_img1.png" alt class="publish-img-1" />
        <img src="../../assets/img/title_big_blue_right.png" alt class="publish-img-2" />
      </div>
    </div>
    <m-sub-title2 class="sub-title2">应用案例</m-sub-title2>
    <div class="scenario">
      <div class="scenario-item">
        <img src="../../assets/img/ksh_pho1.png" alt />
        <div class="scenario-item-name">智慧工业</div>
      </div>
      <div class="scenario-item">
        <img src="../../assets/img/ksh_pho2.png" alt />
        <div class="scenario-item-name">智慧城市</div>
      </div>
      <div class="scenario-item">
        <img src="../../assets/img/ksh_pho3.png" alt />
        <div class="scenario-item-name">智慧商业</div>
      </div>
    </div>
    <m-sub-title2 class="sub-title2">服务模式</m-sub-title2>
    <div class="mode">
      <div class="list">
        <div class="list-item">
          <i class="ri-map-pin-time-line item-icon"></i>
          <div class="item-text">
            <div class="item-title">免费试用体验</div>
            <div class="item-desc">限时免费试用，快来联系我们</div>
          </div>
        </div>
        <div class="list-item no-border">
          <i class="ri-organization-chart item-icon"></i>
          <div class="item-text">
            <div class="item-title">私有部署需求</div>
            <div class="item-desc">对数据敏感？有定制化需求？</div>
          </div>
        </div>
      </div>
    </div>
    <m-bottom-banner>
      <template #title>是否对我们的产品感兴趣了呢？</template>
      <template #desc>期待与您合作！</template>
    </m-bottom-banner>
  </div>
</template>

<script>
import MBanner from "../../components/mobile/MBanner.vue";
import MBottomBanner from '../../components/mobile/MBottomBanner.vue';
import MSubTitle1 from '../../components/mobile/MSubTitle1.vue';
import MSubTitle2 from '../../components/mobile/MSubTitle2.vue';
export default {
  components: { MBanner, MSubTitle1, MSubTitle2, MBottomBanner }
}
</script>

<style lang="less" scoped>
.sub-title1 {
  text-align: left;
  display: flex;
  justify-content: left;
}

.sub-title2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.zero-code {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  text-align: center;
}

.list {
  text-align: left;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .list-item {
    padding: 18px 68px 0 27px;
    box-sizing: border-box;
    height: 90px;
    display: flex;
    border-bottom: 1px solid #eaf0fe;
  }

  .item-text {
    padding-top: 3px;
  }

  .item-title {
    font-size: 14px;
    color: #0f1d2f;
    line-height: 19px;
  }

  .item-desc {
    font-size: 11px;
    margin-top: 9px;
    color: #777f90;
    line-height: 15px;
  }
}

.no-border {
  border: none !important;
}

.item-icon {
  font-size: 26px;
  color: #2e6bf6;
  margin-right: 12px;
}

.base {
  width: 100%;
  height: 240px;
  background-size: 100% 100%;
  background-image: url("../../assets/img/ksh_bg1.jpg");

  .base-bg {
    background: rgba(22, 23, 27, 0.6);
    height: 100%;
    text-align: left;
    padding-top: 63px;
    padding-left: 25px;
    padding-right: 75px;
    margin-top: 20px;
  }

  .base-title {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
    margin-bottom: 20px;
  }

  .base-desc {
    font-size: 11px;
    font-family: Microsoft YaHei, -apple-system;
    color: #ffffff;
    line-height: 17px;
  }

  img {
    margin-top: 20px;
    width: 83px;
    height: 17px;
  }
}

.publish {
  width: 100%;
  height: 240px;
  background: url("../../assets/img/sjksh_bg2.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding-top: 29px;
  padding-right: 25px;
  box-sizing: border-box;

  .publish-title {
    font-size: 14px;
    font-weight: bold;
    color: #0f1d2f;
    line-height: 19px;
  }

  .publish-desc {
    margin-top: 10px;
    font-size: 11px;
    width: 219px;
    color: #777f90;
    line-height: 17px;
  }

  .publish-img {
    display: flex;
    padding-left: 17px;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-start;
    align-self: start;
    margin-top: 20px;
    justify-content: space-between;
  }

  .publish-img-1 {
    width: 220px;
    height: 129px;
  }

  .publish-img-2 {
    width: 83px;
    height: 18px;
  }
}

.scenario {
  display: flex;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 20px;

  .scenario-item {
    img {
      width: 111px;
      height: 75px;
    }

    .scenario-item-name {
      margin-top: 9px;
      font-size: 10px;
      color: #0f1d2f;
      line-height: 13px;
    }
  }
}

.mode {
  margin-bottom: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}
</style>