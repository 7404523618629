import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Popup } from "vant";
import { Swipe, SwipeItem } from 'vant';
var _ = require('lodash');
import './assets/css/icon.css'
import './assets/css/main.less'
import './assets/css/reset.css'
import 'remixicon/fonts/remixicon.css'
import alert from './assets/js/$alert.js'
Vue.prototype.$alert = alert;
Vue.config.productionTip = false
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Popup)
_.chunk()
new Vue({
  router,
  store,
  render: h => h(App),
  /* mounted () {
    document.dispatchEvent(new Event('render-event'))
  } */
}).$mount('#app')
