export default {
  docCookies: {
    getItem: function (sKey) {
      return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    },
    setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
      if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
      var sExpires = "";
      if (vEnd) {
        switch (vEnd.constructor) {
          case Number:
            sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
            break;
          case String:
            sExpires = "; expires=" + vEnd;
            break;
          case Date:
            sExpires = "; expires=" + vEnd.toUTCString();
            break;
        }
      }
      document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
      return true;
    },
    removeItem: function (sKey, sPath, sDomain) {
      if (!sKey || !this.hasItem(sKey)) { return false; }
      document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "")+(sPath ? "; path=" + sPath : "");
      return true;
    },
    hasItem: function (sKey) {
      return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    },
    keys: /* optional method: you can safely remove it! */ function () {
      var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
      for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
      return aKeys;
    }
  },
  ajax(option, callback) {
    const xhr = new XMLHttpRequest();
    xhr.timeout = 10000;
    xhr.open(option.method, option.url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (option.token && this.getItem('x-aep')) {
      xhr.setRequestHeader('Authorization', this.getItem('x-aep').token);
    }
    if (option.data) {
      xhr.send(JSON.stringify(option.data));
    } else {
      xhr.send();
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        console.log('readyState', xhr)
        if (xhr.status == 200) {
          console.log('status', xhr)
          try {
            const response = JSON.parse(xhr.responseText);
            console.log('response', response)
            if (response.code === '8001' || response.code === '401' || response.code === '4208') {
              callback(null);
            } else {
              callback(response);
            }
          } catch (e) {
            callback(e);
          }
        } else {
          callback(new Error(xhr.statusText));
        }
      }
    }
  },
  getItem(key) {
    try {
      const dataString = this.docCookies.getItem(key);
      const data = dataString ? JSON.parse(dataString) : {};
      if (data && data.token) {
        // token 未过期
        return data;
      } else {
        console.log('remove')
        this.docCookies.removeItem(key, '', 'test.senthink.com');
        return null;
      }
    } catch (error) {
      console.log('$getItem---error', error);
      this.docCookies.removeItem(key, '', 'test.senthink.com');
      return null;
    }
  },
}