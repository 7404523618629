<template>
	<div class="all">
		<div class="carousel">
			<img
				class="arrow arrow-left"
				src="../assets/img/arrow_left_n.png"
				alt=""
				@click="carousel('left')"
			>
			<div class="content">
				<ul
					id="carousel"
					:style="{left:position.left+'px',transition:position.transition}"
				>
					<li
						v-for="(item,index) in content"
						:key="index"
					>
						<div class="left">
							<div class="left-nav">
								<img
									:src="item.logo"
									alt=""
								>
								<div class="left-title-box">
									<div class="left-title">{{item.title}}</div>
									<div class="left-describe">{{item.describe[0]}}</div>
									<div class="left-describe">{{item.describe[1]}}</div>
								</div>
							</div>
							<div class="left-content">
								{{item.content}}
							</div>

						</div>
						<div class="right">
							<img
								:src="item.img"
								alt=""
							>
						</div>
					</li>
				</ul>
			</div>
			<img
				class="arrow arrow-right"
				@click="carousel('right')"
				src="../assets/img/arrow_right_n.png"
				alt=""
			>
		</div>
		<div class="pointer-box">
			<div
				class="pointer"
				:class="  {'active':isActive==index+1}"
				@click="pointer(index+1)"
				v-for="item,index in content.length-2"
				:key="index"
			>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['content'],
	data() {
		return {
			isActive: 1,
			lock: true,
			position: {
				left: -1118,
				transition: '0.5s all ease-in-out',
			},
		}
	},
	created() {
		this.content.push(this.content[0])
		this.content.unshift(this.content[this.content.length - 2])
	},
	mounted() {

	},
	methods: {
		/* 圆点点击滑动 */
		pointer(index) {
			this.position.left = index * -1118
			this.isActive = index;
			this.position.transition = '0.5s all ease-in-out'
		},
		/* 箭头点击滑动 */
		carousel(direction) {
			if (!this.lock) return
			if (direction == 'right') {
				this.position.left -= 1118
				this.position.transition = '0.5s all ease-in-out'
				this.isActive++;
				if (Math.abs(this.position.left) / 1118 >= this.content.length - 1) {
					this.isActive = 1;
				}
			}
			else {
				this.position.left += 1118
				this.position.transition = '0.5s all ease-in-out'
				this.isActive--;
				if (Math.abs(this.position.left) <= 0) {
					this.isActive = this.content.length - 2;
				}
			}
			setTimeout(() => {
				if (Math.abs(this.position.left) / 1118 >= this.content.length - 1) {
					this.position.left = -1118
					this.position.transition = 'none'
				}
				else if (Math.abs(this.position.left) <= 0) {
					this.position.left = -1118 * (this.content.length - 2);
					this.position.transition = 'none'
				}
			}, 500);
			this.lock = false;
			setTimeout(() => {
				this.lock = true
			}, 500);
		},
	},
	computed: {
		left() {
			return { left: this.position.left * this.currentIndex + 'px', transition: this.position.transition }
		}
	}
}
</script>
<style lang="less" scoped>
.carousel {
	display: flex;
	align-items: center;
	justify-content: center;
	.arrow {
		cursor: pointer;
	}
	.arrow-left:hover {
		content: url(../assets/img/arrow_left_s.png);
	}
	.arrow-right:hover {
		content: url(../assets/img/arrow_right_s.png);
	}
}
.all {
	display: block;
}
.pointer-box {
	display: flex;
	justify-content: center;
	margin-top: 0px;
	.pointer {
		width: 8px;
		height: 8px;
		background: #d5e1fd;
		border-radius: 50%;
		cursor: pointer;
		margin: 0 6px;
	}
	.pointer:hover {
		background: @main-color;
	}
}

.content {
	display: inline-flex;
	width: 1118px;
	height: 500px;

	align-items: center;
	position: relative;
	overflow: hidden;
	ul {
		position: absolute;
		display: inline-flex;
		/* transition: 0.5s all ease-in-out; */

		li {
			display: inline-flex;
			width: 1020px;
			height: 420px;
			margin: 0 49px;
			box-shadow: 4px 4px 20px 0px rgba(46, 107, 246, 0.16);
			border-radius: 8px;
			.left {
				flex: 1;
				text-align: left;
				.left-nav {
					display: flex;
					height: 100%;
					height: 110px;
					margin-top: 60px;
					margin-left: 60px;
					img {
						width: 100px;
						height: 100px;
						border: 1px solid #e5e5e5;
						border-radius: 50%;
					}
					.left-title-box {
						margin-top: 20px;
						margin-left: 20px;
						.left-title {
							margin-bottom: 12px;
							height: 42px;
							font-size: 32px;
							font-family: MicrosoftYaHei-Bold, Microsoft YaHei,-apple-system;
							font-weight: bold;
							color: #0f1d2f;
						}
						.left-describe {
							display: inline-block;
							border: 1px solid @main-color;
							height: 24px;
							margin-right: 8px;
							font-size: 12px;
							color: @main-color;
							line-height: 24px;
							padding: 0 12px;
						}
					}
				}
				.left-content {
					margin-top: 40px;
					width: 389px;
					font-size: 16px;
					color: @h2-color;
					line-height: 28px;
					margin-left: 60px;
				}
			}
			.right {
				flex: 1;
				img {
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
				}
			}
		}
	}
}
.active {
	background: @main-color !important;
}
</style>