import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import isMobile from '../assets/js/isMobile'
import MIndex from '../views/m_index.vue'
import About from '../views/about/index.vue'
import IoT from '../views/iot/index.vue'
import Router from '../views/router/index.vue'
import ECooperation from '../views/eCooperation/index.vue'
import DataVisualization from '../views/dataVisualization/index.vue'
import Miot from '../views/m_iot/index.vue'
import MRouter from '../views/m_router/index.vue'
import MAbout from '../views/m_about/index.vue'
import MDataVisualization from '../views/m_dataVisualization/index.vue'
import MECooperation from '../views/m_eCooperation/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/iot',
    name: 'IoT',
    component: IoT
  },
  {
    path: '/router',
    name: 'Router',
    component: Router
  },
  {
    path: '/eCooperation',
    name: 'ECooperation',
    component: ECooperation
  },
  {
    path: '/dataVisualization',
    name: 'DataVisualization',
    component: DataVisualization
  },
  {
    path: '/m_index',
    name: 'MIndex',
    component: MIndex,
  },
  {
    path: '/m_iot',
    name: 'Miot',
    component: Miot
  },
  {
    path: '/m_router',
    name: 'MRouter',
    component: MRouter
  },
  {
    path: '/m_about',
    name: 'MAbout',
    component: MAbout
  },
  {
    path: '/m_dataVisualization',
    name: 'MDataVisualization',
    component: MDataVisualization
  },
  {
    path: '/m_eCooperation',
    name: 'MECooperation',
    component: MECooperation
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const mobilepath = [
  '/m_iot',
  '/m_router',
  '/m_ecooperation',
  '/m_about',
  '/m_datavisualization',
  '/m_iot/',
  '/m_router/',
  '/m_ecooperation/',
  '/m_about/',
  '/m_datavisualization/'
]
const pcpath = [
  '/iot',
  '/router',
  '/ecooperation',
  '/about',
  '/datavisualization',
  '/iot/',
  '/router/',
  '/ecooperation/',
  '/about/',
  '/datavisualization/'
]
router.beforeEach((to, from, next) => {
  let userAgent = navigator.userAgent
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  if (isMobile(userAgent) && to.path == '/') {
    next('/m_index')
  }
  else if (!isMobile(userAgent) && to.path == '/m_index') {
    next('/')
  }
  else if (isMobile(userAgent) && pcpath.includes(to.path.toLowerCase())) {
    next(`/m_${to.path.substring(1, to.path.length)}`)
  }
  else if (!isMobile(userAgent) && mobilepath.includes(to.path.toLowerCase())) {
    next(`/${to.path.substring(3, to.path.length)}`)
  }
  else {
    next()
  }
})
router.afterEach((to, from, next) => {
  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      //每次执行前，先移除上次插入的代码
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?0cf118345c06b17afd7a64ab29917599";
      hm.id = "baidu_tj"
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
});
export default router
