<template>
	<div
		class="title-box"
		:style="{background:'url('+title.background+') no-repeat center top',backgroundSize: 'cover'}"
	>
		<div class="title-content">
			<div class="title">
				<div class="title-font title-text">{{title.title}}</div>
				<div
					class="title-describe-font title-describe"
					:style="describeStyle"
				>
					{{title.describe}}
				</div>
			</div>
			<img
				:src="title.img"
				alt=""
			>
		</div>
	</div>
</template>
<script>
import WhiteButton from './WhiteButton.vue'
export default {
	components: { WhiteButton },
	props: ['title', 'describeStyle']
}
</script>
<style lang="less" scoped>
.title-box {
	width: 100%;
	height: 504px;
	text-align: left;
	.title-content {
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		margin: 0 auto;
		display: flex;
		.title-text {
			padding-top: 164px;
		}
		.title-describe {
			width: 460px;
			line-height: 27px;
			margin-top: 40px;
		}
		.consult {
			margin-top: 40px;
		}
		img {
			margin-left: 189px;
			width: 480px;
			height: 340px;
			margin-top: 119px;
		}
	}
}
</style>