<template>
	<div id="app">
		<x-m-header v-if="mobile"></x-m-header>
		<x-header v-else></x-header>
		<router-view />
		<x-m-footer v-if="mobile"></x-m-footer>
	</div>
</template>
<script>
import XMFooter from './components/mobile/XMFooter.vue'
import XMHeader from './components/mobile/XMHeader.vue'
import xHeader from './components/xHeader.vue'
export default {
	components: { xHeader, XMHeader, XMFooter },
	data() {
		return {
			mobile: false,
		}
	},
	created() {
		this.mobile = window.navigator.userAgent.match(/Mobile.*/)
	}
}
</script>
<style lang="less">
.alert {
	color: #333333;
	position: fixed;
	z-index: 99999999999;
	height: 40px;
	width: 400px;
	top: 64px;
	left: 50%;
	line-height: 40px;
	font-size: 20px;
	text-align: left;
	border-radius: 4px;
	box-shadow: 0px 0px 10px rgba(0, 59, 140, 0.1);
	padding: 0 10px 0 40px;
	background: url("./assets/img/success.png") no-repeat 10px center;
	background-color: #fff;
	background-size: 15px 15px;
}

//失败弹窗
.error {
	background: url("./assets/img/error.png") no-repeat 10px center;
	background-color: #fff;
	background-size: 15px 15px;
}
</style>
