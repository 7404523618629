<template>
  <div>
    <m-banner :url="require('../../assets/img/banner_iot.png')">
      <template #title>物联网全连接云平台</template>
      <template #img>
        <img src="../../assets/img/sjksh.gif" alt />
      </template>
      <template #desc>为设备提供安全可靠的连接管理能力，整合物联网产业链上下游，向下连接智能设备，向上承接物联网应用，形成端到云完整链路物联网解决方案，赋能企业各类IOT场景接入</template>
    </m-banner>
    <div class="content">
      <m-sub-title-2 class="sub-title2">强大、安全、稳定、灵活</m-sub-title-2>
      <div class="part-1">
        <div class="part-1-item">
          <img src="../../assets/img/wjr.png" alt />
          <div class="part-1-item-title">稳接入</div>
          <div class="part-1-item-desc">提供稳定的接入服务，适配多种网络和多种接入协议</div>
        </div>
        <div class="part-1-item">
          <img src="../../assets/img/gky.png" alt />
          <div class="part-1-item-title">高可用</div>
          <div class="part-1-item-desc">具有海量设备连接能力和并发能力，微服务架构，集群部署，支持弹性水平拓展</div>
        </div>
        <div class="part-1-item">
          <img src="../../assets/img/aqx.png" alt />
          <div class="part-1-item-title">安全性</div>
          <div class="part-1-item-desc">设备认证与权限管理，保障设备安全与唯一性；传输加密保障数据安全传输；关键业务数据加密,保障数据不被篡改</div>
        </div>
        <div class="part-1-item">
          <img src="../../assets/img/dcb.png" alt />
          <div class="part-1-item-title">低成本</div>
          <div class="part-1-item-desc">多种低成本远程运维手段，降低设备出货后人力运维成本</div>
        </div>
        <div class="part-1-item">
          <img src="../../assets/img/dcz.png" alt />
          <div class="part-1-item-title">多承载</div>
          <div class="part-1-item-desc">平台提供丰富的API接口，满足应用各种开发场景</div>
        </div>
        <div class="part-1-item">
          <img src="../../assets/img/dzh.png" alt />
          <div class="part-1-item-title">定制化</div>
          <div class="part-1-item-desc">架构灵活，既可以公有部署，降低系统运维成本，也可以快速私有定制部署，可适合不同客户的需求</div>
        </div>
      </div>
      <m-sub-title-2 class="sub-title2">客户场景</m-sub-title-2>
      <div class="sub-desc">专业物联网解决方案，倾力满足企业所需</div>
      <div class="list-title-group">
        <div
          class="list-title"
          @click="selectList(1)"
          :class="selectedList == 1 ? 'list-active' : ''"
        >助力企业IoT开发</div>
        <div
          class="list-title"
          @click="selectList(2)"
          :class="selectedList == 2 ? 'list-active' : ''"
        >私有化按需定制</div>
      </div>
      <div class="select" :style="`transform:translateX(${select1Line}px) translateX(-50%)`"></div>
      <div class="list">
        <div class="list-item">
          <img class="item-icon" src="../../assets/img/icon_problem.png" alt />
          <div class="item-text">
            <div class="item-title">客户痛点</div>
            <div v-if="selectedList == 1" class="item-desc">
              传统硬件生产企业自建平台门槛高，云厂商套件复杂，
              入门困难。
            </div>
            <div v-else class="item-desc">若严重依赖云厂商会被生态裹挟，可定制化弱，内网部署强需求。</div>
          </div>
        </div>
        <div class="list-item no-border">
          <img class="item-icon" src="../../assets/img/icon_resolve.png" alt />
          <div class="item-text">
            <div class="item-title">解决方案</div>
            <div v-if="selectedList == 1" class="item-desc">
              产品厂家专心做好自己擅长部分，我们帮助企业快速构建
              物联网应用程序。
            </div>
            <div v-else class="item-desc">自主可控，按需定制，公有云、私有云、企业机房灵活部署。</div>
          </div>
        </div>
      </div>
      <m-sub-title-2 class="sub-title2">平台架构</m-sub-title-2>
      <div class="sub-desc">
        是企业设备连接的物联网平台，可将设备数据流转到各自
        应用系统，是构建物联网应用的基础
      </div>
      <img class="part-3-img" src="../../assets/img/section_3_bg.png" alt />
      <div class="watch-img" @click="showImg(0)">点击查看架构图</div>
      <m-sub-title-2 class="sub-title2">平台功能</m-sub-title-2>
      <div class="sub-desc">面向企业，打造专属</div>
      <div class="list-title-group">
        <div
          class="list-title-2"
          @click="selectList2(1)"
          :class="selectedList2 == 1 ? 'list-active' : ''"
        >设备接入</div>
        <div
          class="list-title-2"
          @click="selectList2(2)"
          :class="selectedList2 == 2 ? 'list-active' : ''"
        >设备管理</div>
        <div
          class="list-title-2"
          @click="selectList2(3)"
          :class="selectedList2 == 3 ? 'list-active' : ''"
        >运维监控</div>
        <div
          class="list-title-2"
          @click="selectList2(4)"
          :class="selectedList2 == 4 ? 'list-active' : ''"
        >应用承载</div>
        <div
          class="list-title-2"
          @click="selectList2(5)"
          :class="selectedList2 == 5 ? 'list-active' : ''"
        >数据流转</div>
      </div>
      <div class="select-2" :style="left"></div>
      <div class="part-4">
        <div class="list">
          <div
            class="list-item"
            :class="index == part4List[selectedList2 - 1].length - 1 ? 'no-border' : ''"
            v-for="item, index in part4List[selectedList2 - 1]"
            :key="index"
          >
            <img class="item-icon" :src="item.img" alt />
            <div class="item-text">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <m-sub-title-2 class="sub-title2">定位服务</m-sub-title-2>
    <div class="sub-desc">
      接入平台的智能设备可通过上报周围的基站，
      <br />Wi-Fi信息来获取对应的地理位置信息
    </div>
    <div class="part-5">
      <div class="part5-item">
        <img src="../../assets/img/dingwei_1.png" alt />
        <div class="part5-item-text">基站定位</div>
      </div>
      <div class="part5-item">
        <img src="../../assets/img/dingwei_2.png" alt />
        <div class="part5-item-text">Wi-Fi定位</div>
      </div>
      <div class="part5-item">
        <img src="../../assets/img/dingwei_3.png" alt />
        <div class="part5-item-text">实时位置</div>
      </div>
      <div class="part5-item">
        <img src="../../assets/img/dingwei_4.png" alt />
        <div class="part5-item-text">混合定位</div>
      </div>
      <div class="part5-item">
        <img src="../../assets/img/dingwei_5.png" alt />
        <div class="part5-item-text">位置转换</div>
      </div>
      <div class="part5-item">
        <img src="../../assets/img/dingwei_6.png" alt />
        <div class="part5-item-text">历史轨迹</div>
      </div>
    </div>
    <div class="content">
      <m-sub-title-2 class="sub-title2">成功案例</m-sub-title-2>
      <div class="sub-desc">
        我们为企业提供云端物联网解决方案，让您的系统
        私有化，数据私有化
      </div>
      <div class="list-title-group">
        <div
          class="list-title-2"
          @click="selectList3(0)"
          :class="selectedList3 == 0 ? 'list-active-2' : ''"
        >楼宇检测</div>
        <div
          class="list-title-2"
          @click="selectList3(1)"
          :class="selectedList3 == 1 ? 'list-active-2' : ''"
        >共享按摩椅</div>
        <div
          class="list-title-2"
          @click="selectList3(2)"
          :class="selectedList3 == 2 ? 'list-active-2' : ''"
        >智能咖啡机</div>
      </div>
      <div class="select-3" :style="`transform:translateX(${select3Line}px) translateX(-50%)`"></div>
      <div class="part-6">
        <div class="part-6-h1">{{ successCase[selectedList3].name }}</div>
        <div class="part-6-desc">{{ successCase[selectedList3].desc }}</div>
        <div class="part-6-h1">{{ successCase[selectedList3].listName }}</div>
        <div class="part-6-list">
          <div
            class="part-6-list-item"
            v-for="item, index in successCase[selectedList3].listItem"
            :key="index"
          >
            <div class="item-top">
              <div class="dot"></div>
              <div class="part-6-list-item-name">{{ item.name }}</div>
            </div>
            <div class="part-6-list-item-desc">{{ item.desc }}</div>
          </div>
        </div>
        <img :src="successCase[selectedList3].img" alt />
      </div>
      <div class="watch-img" @click="showImg(selectedList3 + 1)">点击查看架构图</div>
      <m-sub-title-2 class="sub-title2">安全认证</m-sub-title-2>
      <div class="sub-desc">安全、合规、可靠的物联网平台</div>
      <div class="part-7">
        <div class="part-7-box">
          <div class="part-7-title-card">
            <img src="../../assets/img/icon_7_1.png" alt />
            <div class="part-7-title-card-text">公有托管</div>
          </div>
          <div class="part-7-list">
            <div class="part-7-list-item">
              <div class="part-7-list-item-top">
                <div class="dot"></div>
                <div class="part-7-list-item-name">低成本</div>
              </div>
              <div class="part-7-list-item-desc">公有云按年收费，最低成本上云</div>
            </div>
            <div class="part-7-list-item">
              <div class="part-7-list-item-top">
                <div class="dot"></div>
                <div class="part-7-list-item-name">托管式服务</div>
              </div>
              <div class="part-7-list-item-desc">使用公有云无需自己搭建服务器</div>
            </div>
          </div>
        </div>
        <div class="part-7-box">
          <div class="part-7-title-card">
            <img src="../../assets/img/icon_7_2.png" alt />
            <div class="part-7-title-card-text">私有部署</div>
          </div>
          <div class="part-7-list">
            <div class="part-7-list-item">
              <div class="part-7-list-item-top">
                <div class="dot"></div>
                <div class="part-7-list-item-name">私有数据</div>
              </div>
              <div class="part-7-list-item-desc">部署自己的服务器上，数据、系统私有化</div>
            </div>
            <div class="part-7-list-item">
              <div class="part-7-list-item-top">
                <div class="dot"></div>
                <div class="part-7-list-item-name">个性化定制</div>
              </div>
              <div class="part-7-list-item-desc">可根据不同的业务场景做个性化定制</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <m-bottom-banner>
      <template #title>是否对我们的产品感兴趣了呢？</template>
      <template #desc>期待与您合作！</template>
    </m-bottom-banner>
  </div>
</template>

<script>
import MBanner from '../../components/mobile/MBanner.vue'
import MSubTitle2 from '../../components/mobile/MSubTitle2.vue'
import { ImagePreview } from 'vant';
import MBottomBanner from '../../components/mobile/MBottomBanner.vue';
export default {
  components: { MBanner, MSubTitle2, MBottomBanner },
  data() {
    return {
      selectedList: 1,
      selectedList2: 1,
      selectedList3: 0,
      select1Line: 0,
      select3Line: 0,
      successCase: [
        {
          name: '楼宇检测',
          desc: '写字楼中的传感器数据上报，平台将数据转发至楼宇应用系统，楼宇系统收到数据后对数据进行分析和可视化展示。',
          listName: '物联网平台提供',
          listItem: [
            {
              name: '设备接入',
              desc: '平台保持与设备稳定连接。'
            },
            {
              name: '高并发',
              desc: '传感器数据上报非常频繁，通过负载均衡和集群架构解决高并发。'
            },
            {
              name: '数据流转',
              desc: '设备数据上报至平台后第一时间推送至应用订阅的服务组件，低延时。'
            },
          ],
          img: require('../../assets/img/m_section_6_bg_1.png')
        },
        {
          name: '共享按摩椅',
          desc: '共享按摩椅将在线状态和使用状态等信息上报云端，用户扫码后云端低延时下发指令控制按摩椅运行。',
          listName: '物联网平台提供',
          listItem: [
            {
              name: '通信安全',
              desc: '设备身份认证，双向通信加密，确保安全可靠'
            },
            {
              name: '低延时',
              desc: '确保指令下发的及时性，提升用户扫码使用的体验'
            },
            {
              name: '实时监控',
              desc: '实时监控设备在线状态，并第一时间与应用同步，确保按摩椅运营者及时掌握设备运行情况'
            },
          ],
          img: require('../../assets/img/m_section_6_bg_2.png')
        },
        {
          name: '智能咖啡机',
          desc: '咖啡机连云后，云上可实时感知设备状态，通过OTA方式升级媒体、菜单和配置，实现咖啡机智能精细化运营，起到降本增效的作用。',
          listName: '物联网平台提供',
          listItem: [
            {
              name: 'OTA升级',
              desc: '指定升级策略，包含群组、时间、版本号等'
            },
            {
              name: '低成本',
              desc: '远程监控设备状态和下发资料更新，大大节省了人工成本'
            },
            {
              name: '私有化',
              desc: '企业数据私有化、系统私有化，保障数据资产安全'
            },
          ],
          img: require('../../assets/img/m_section_6_bg_3.png')
        },
      ],
      part4List: [
        [
          {
            img: require('../../assets/img/icon_4_1_1.png'),
            title: '多网络接入',
            desc: '支持蜂窝、NB-IOT、WI-FI、有线等多网络接入，屏蔽物联网碎片化，实现设备快速接入。'
          },
          {
            img: require('../../assets/img/icon_4_1_2.png'),
            title: '多协议接入',
            desc: '支持设备通过MQTT、TCP/透传、HTTP、UDP、CoAP、LwM2M等协议接入。'
          },
          {
            img: require('../../assets/img/icon_4_1_3.png'),
            title: '安全接入',
            desc: '提供—型—密免注册、预注册和—机一密预注册等设备安全认证机制，防止设备非法接入。'
          },
          {
            img: require('../../assets/img/icon_4_1_4.png'),
            title: '云云接入',
            desc: '支持第三方云平台接入，如阿里IoT、电信AEP、移动OneNet、亚马逊IoT等。'
          },
          {
            img: require('../../assets/img/icon_4_1_5.png'),
            title: '网关接入',
            desc: '支持设备通过网关方式接入，网关与子设备拓扑架构的管理。'
          },
          {
            img: require('../../assets/img/icon_4_1_6.png'),
            title: '双向通信',
            desc: '设备与云端双向通信，平台提供安全的传输通道，保障设备上报与指令下发稳定可靠。'
          }
        ],
        [
          {
            img: require('../../assets/img/icon_4_2_1.png'),
            title: '物模型',
            desc: '提供物模型定义功能,可将设备属性、服务、事件使用标准的格式进行数字化表示。'
          },
          {
            img: require('../../assets/img/icon_4_2_2.png'),
            title: '数据解析',
            desc: '将设备透传的非标准格式数据通过数据解析与平台标准格式进行转化。'
          },
          {
            img: require('../../assets/img/icon_4_2_3.png'),
            title: '设备生命周期',
            desc: '设备生命周期管理，包含设备注册、设备更新、设备查询、设备禁用、设备删除。'
          },
          {
            img: require('../../assets/img/icon_4_2_4.png'),
            title: '在线状态监控',
            desc: '平台实时监控设备的状态，包括在线、离线、未激活，实时获取状态变更通知。'
          },
          {
            img: require('../../assets/img/icon_4_2_5.png'),
            title: '在线调试',
            desc: '提供在线调试功能，便于用户快速调试设备以及定位设备问题。'
          },
          {
            img: require('../../assets/img/icon_4_2_6.png'),
            title: '设备影子',
            desc: '缓存设备最新状态与命令，避免网络不稳定导致无法实时控制设备，无需关心设备是否在线。'
          }
        ],
        [
          {
            img: require('../../assets/img/icon_4_3_1.png'),
            title: 'OTA升级',
            desc: '支持静态、动态、群组升级等多种策略设置,赋能设备远程升级。'
          },
          {
            img: require('../../assets/img/icon_4_3_2.png'),
            title: '日志分析',
            desc: '设备和平台全链路日志记录，可通过多种维度快速检索分析'
          },
          {
            img: require('../../assets/img/icon_4_3_3.png'),
            title: '远程配置',
            desc: '可在不用重启设备或中断设备运行情况下，在线远程更新设备的系统参数、网络参数等配置信息。'
          },
          {
            img: require('../../assets/img/icon_4_3_4.png'),
            title: '实时监控',
            desc: '平台提供在线设备数量、上下行消息数量、设备网络状态等指标数据的实时监控功能。'
          },
          {
            img: require('../../assets/img/icon_4_3_5.png'),
            title: '文件上传',
            desc: '设备可将运行日志、配置信息等文件上传到平台，便于日志分析、故障定位以及设备数据备份。'
          },
          {
            img: require('../../assets/img/icon_4_3_6.png'),
            title: '告警通知',
            desc: '基于规则引擎设置的告警规则，可触发短信、邮箱、钉钉、微信等实时通知。'
          }
        ],
        [
          {
            img: require('../../assets/img/icon_4_4_1.png'),
            title: '应用订阅',
            desc: '应用可自由订阅设备的状态变更、设备消息上报、OTA结果等消息。'
          },
          {
            img: require('../../assets/img/icon_4_4_2.png'),
            title: '消息推送',
            desc: '平台根据应用订阅推送类型将消息转发至各通信组件，如HTTP、RabbitMQ、MQTT等。'
          },
          {
            img: require('../../assets/img/icon_4_4_3.png'),
            title: 'Open API',
            desc: '开放平台API，满足各种应用开发需求。'
          },
        ],
        [
          {
            img: require('../../assets/img/icon_4_5_1.png'),
            title: '消息上报',
            desc: '设备接入到物联网平台后，可通过指定的Topic或通道将数据上报到平台。'
          },
          {
            img: require('../../assets/img/icon_4_5_2.png'),
            title: '消息路由',
            desc: '平台对数据路由灵活控制，将设备数据进行安全流转。'
          },
          {
            img: require('../../assets/img/icon_4_5_3.png'),
            title: '规则引擎',
            desc: '使用SQL语句来对数据进行清洗处理，配置规则并将处理后的数据转发到其他服务或应用。'
          },
          {
            img: require('../../assets/img/icon_4_5_4.png'),
            title: '传输加密',
            desc: '基于TLS、DTLS+加密协议，提供安全的传输通道。'
          },
        ],
      ]
    }
  },
  methods: {

    selectList(itemKey) {
      this.selectedList = itemKey
      this.translate('select1Line', 'list-active')
    },
    selectList2(itemKey) {
      this.selectedList2 = itemKey
    },
    selectList3(itemKey) {
      this.selectedList3 = itemKey
      this.translate('select3Line', 'list-active-2')
    },
    showImg(i) {
      const url = [
        require('../../assets/img/m_section_3_bg.png'),
        require('../../assets/img/m_section_6_bg_1.png'),
        require('../../assets/img/m_section_6_bg_2.png'),
        require('../../assets/img/m_section_6_bg_3.png'),
      ]
      ImagePreview([
        url[i]
      ]);
    },
    translate(line, className) {
      this.$nextTick(() => {
        this[line] = document.getElementsByClassName(className)[0].offsetLeft - 15 + document.getElementsByClassName(className)[0].offsetWidth / 2;
      })
    },
  },
  computed: {
    left() {
      const selectedList2 = this.selectedList2
      const gap = '(100% - 5 * 52px) / 10'
      return `left: calc((${gap} + 11px) + (${selectedList2} - 1) * (${gap} * 2 + 52px));`
    },

  },
  created() {
    this.translate('select3Line', 'list-active-2')
    this.translate('select1Line', 'list-active')
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}
.part-1 {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 33.3%);
  grid-template-columns: 50% 50%;
  grid-gap: 1px;
  .part-1-item {
    background: #fff;
    height: 200px;
    img {
      margin-top: 25px;
      width: 46px;
      height: 46px;
    }
    .part-1-item-title {
      font-size: 13px;
      color: #0f1d2f;
      line-height: 18px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .part-1-item-desc {
      text-align: justify;
      margin: 0 10px;
      font-size: 11px;
      color: #777f90;
      line-height: 18px;
    }
  }
}
.part-3-img {
  width: 100%;
  padding: 13px 10px;
  box-sizing: border-box;
  margin-top: -10px;
}
.part-5 {
  width: 100%;
  height: 161px;
  background: url("../../assets/img/bg_ditu.png");
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  .part5-item {
    width: 33.3%;
    img {
      width: 25px;
      height: 25px;
    }
    .part5-item-text {
      font-size: 12px;
      margin-top: 10px;
      color: #16171b;
      line-height: 16px;
    }
  }
}
.part-6 {
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  padding: 0 10px;
  box-sizing: border-box;
  .part-6-h1 {
    font-size: 13px;
    color: #16171b;
    line-height: 18px;
    margin-bottom: 10px;
    padding-top: 20px;
  }
  .part-6-desc {
    font-size: 12px;
    color: #777f90;
    line-height: 20px;
  }
  .part-6-list-item {
    .item-top {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    .part-6-list-item-name {
      font-size: 12px;
      color: #16171b;
      line-height: 16px;
    }
    .part-6-list-item-desc {
      font-size: 11px;
      color: #777f90;
      line-height: 18px;
      margin-left: 16px;
      margin-bottom: 10px;
    }
    .dot {
      width: 6px;
      height: 6px;
      background: #2e6bf6;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  img {
    margin-bottom: 10px;
    width: 100%;
  }
}
.part-7 {
  width: 100%;
  background: #fff;
  display: flex;
  margin-bottom: 20px;

  .part-7-box {
    flex: 1;
    box-sizing: border-box;
    padding: 0 10px;
    &:nth-of-type(1) {
      border-right: 1px solid #eaf0fe;
    }
  }
  .part-7-title-card {
    text-align: center;
    img {
      height: 42px;
      margin-top: 30px;
    }
    .part-7-title-card-text {
      font-size: 13px;
      margin-top: 15px;
      color: #0f1d2f;
      line-height: 18px;
      margin-bottom: 15px;
    }
  }
  .part-7-list {
    text-align: left;
    margin-bottom: 20px;
    .part-7-list-item {
      .part-7-list-item-top {
        display: flex;
        align-items: center;
      }
      .dot {
        width: 6px;
        height: 6px;
        background: #2e6bf6;
        border-radius: 50%;
        margin-right: 10px;
      }
      .part-7-list-item-name {
        font-size: 12px;
        color: #16171b;
        line-height: 16px;
      }
      .part-7-list-item-desc {
        min-height: 38px;
        font-size: 11px;
        color: #777f90;
        line-height: 18px;
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
  }
}
.watch-img {
  width: 100%;
  height: 34px;
  text-align: center;
  background: #d5e1fd;
  font-size: 12px;
  color: #2e6bf6;
  line-height: 34px;
}
.list-title-group {
  display: flex;
  justify-content: space-around;
}
.list-title {
  font-size: 13px;
  color: #777f90;
  line-height: 18px;
  margin-bottom: 15px;
}
.list-title-2 {
  font-size: 13px;
  color: #777f90;
  line-height: 18px;
  margin-bottom: 10px;
}
.select {
  width: 50px;
  height: 2px;
  background: #2e6bf6;
  transition: all ease-in-out 0.3s;
}
.select-2 {
  width: 30px;
  height: 2px;
  background: #2e6bf6;
  transition: all ease-in-out 0.3s;
  position: relative;
}
.select-3 {
  width: 30px;
  height: 2px;
  background: #2e6bf6;
  transition: all ease-in-out 0.3s;
  position: relative;
}
.select-active {
  margin-left: 70%;
}
.list-active {
  color: #16171b;
  line-height: 18px;
}
.sub-title1 {
  text-align: left;
  display: flex;
  justify-content: left;
}
.sub-title2 {
  margin-top: 30px;
  margin-bottom: 20px;
}
.sub-desc {
  margin: -10px 24px 30px;
  font-size: 12px;
  color: #777f90;
  line-height: 20px;
}
.list {
  text-align: left;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  .list-item {
    padding: 18px 24px 0 27px;
    box-sizing: border-box;
    height: 90px;
    display: flex;
    border-bottom: 1px solid #eaf0fe;
  }
  .item-text {
    padding-top: 3px;
  }
  .item-title {
    font-size: 14px;
    color: #0f1d2f;
    line-height: 19px;
  }
  .item-desc {
    font-size: 11px;
    margin-top: 9px;
    color: #777f90;
    line-height: 18px;
  }
  .item-icon {
    width: 26px;
    height: 26px;
    font-size: 26px;
    margin-right: 12px;
  }
}
.no-border {
  border: none !important;
}
</style>