<template>
	<div class="index">
		<img src="../assets/img/logo3.png" alt="" style="visibility: hidden;display:block; position:absolute;width: 0;height: 0;"/>
		<div style="position:relative;">
			<div class="video-box">
				<video :src="require('../assets/video/video.mp4')" muted loop autoplay></video>
				<div class="overlay"></div>
			</div>
			<div class="content">
				<div class="title-box">
					<div class="title-font index-title">利尔达物联网全连接云平台</div>
					<div
						class="title-describe-font index-title-describe"
					>为企业提供物联网全连接云平台、智能工业网关、数据可视化等专业物联网服务，助力客户快速搭建物联网应用。</div>
				</div>
				<div class="IoT">
					<sub-title-1>物联网全连接云平台</sub-title-1>
					<div class="sub-content">
						<div class="IoT-left">
							<div
								class="sub-title-describe-font IoT-title-describe"
							>为设备提供安全可靠的连接管理能力，整合物联网产业链上下游，向下连接智能设备，向上承接物联网应用，形成端到云完整链路物联网解决方案，赋能企业各类IoT场景接入。</div>
							<div class="IoT-card-box">
								<div
									class="IoT-card"
									:class="active == 1 ? 'active' : ''"
									@mousemove="ioTCardOver(1)"
									@mouseleave="ioTCardLeave()"
								>
									<div class="IoT-card-icon">
										<i class="ri-link icon"></i>
									</div>
									<div class="IoT-card-text">海量碎片化连接</div>
								</div>
								<div
									class="IoT-card"
									:class="active == 2 ? 'active' : ''"
									@mousemove="ioTCardOver(2)"
									@mouseleave="ioTCardLeave()"
								>
									<div class="IoT-card-icon">
										<i class="ri-shape-line icon"></i>
									</div>
									<div class="IoT-card-text">物模型规则引擎</div>
								</div>
								<div
									class="IoT-card"
									:class="active == 3 ? 'active' : ''"
									@mousemove="ioTCardOver(3)"
									@mouseleave="ioTCardLeave()"
								>
									<div class="IoT-card-icon">
										<i class="ri-router-line icon"></i>
									</div>
									<div class="IoT-card-text">数据路由分发</div>
								</div>
							</div>
							<detail @click.native="push('/iot', '物联网全连接云平台')"></detail>
						</div>
						<div class="IoT-right">
							<img src="../assets/img/sy_img_iot.png" alt />
						</div>
					</div>
				</div>
				<div class="router">
					<div class="router-left">
						<img src="../assets/img/sy_img_lyq.png" alt />
					</div>
					<div class="router-right">
						<sub-title-1>智能工业路由器</sub-title-1>
						<div
							class="sub-title-describe-font router-title-describe"
						>专为非智能设备打造的智能工业路由器，支持RS485、RS232和以太网设备接入，具有工业协议解析、数据清洗和数据转发功能，快速实现非IoT设备数据采集和指令控制。</div>
						<div class="router-box">
							<router-box>
								<template #default>
									<i class="ri-numbers-line ri-icon"></i>
								</template>
								<template #title>高效</template>
								<template #content>JS脚本快速开发解析协议，比传统开发减少80%时间</template>
							</router-box>
							<router-box>
								<template #default>
									<i class="ri-psychotherapy-line ri-icon"></i>
								</template>
								<template #title>智能</template>
								<template #content>自带精简版Linux系统，主频高达580MHz</template>
							</router-box>
							<router-box>
								<template #default>
									<i class="ri-vip-crown-line ri-icon"></i>
								</template>
								<template #title>强大</template>
								<template #content>同时支持串口数据到4G和以太网网络，支持远程登录与升级</template>
							</router-box>
							<router-box>
								<template #default>
									<i class="ri-rocket-2-line ri-icon"></i>
								</template>
								<template #title>高速</template>
								<template #content>
									4G无线上网+双频Wi-Fi
									300Mbps
								</template>
							</router-box>
							<detail @click.native="push('/router', '智能工业路由器')"></detail>
						</div>
					</div>
				</div>
			</div>
			<div class="visualization-box">
				<div class="visualization">
					<sub-title-2 class="vis-title">数据可视化</sub-title-2>
					<div class="sub-title-describe-font vis-title-describe">快捷、易用的数据可视化工具，企业可快速搭建设备可视化、数据大屏可视化</div>
					<detail class="vis-detail" @click.native="push('/dataVisualization', '数据可视化')"></detail>
					<div class="vis-img">
						<div class="img-left">
							<div class="img-box">
								<img src="../assets/img/sy_pho1.png" alt />
								<div class="img-title">丰富的数据组件</div>
							</div>
							<div class="img-box">
								<img src="../assets/img/sy_pho2.png" alt />
								<div class="img-title">多种数据源对接</div>
							</div>
						</div>
						<div class="img-right">
							<img src="../assets/img/sy_pho3.png" alt />
							<div class="img-title">拖拽式编辑界面</div>
						</div>
					</div>
				</div>
			</div>
			<div class="trust">
				<sub-title-2 class="trust-title">来自客户的信任</sub-title-2>
				<div class="sub-title-describe-font trust-title-describe">20年物联网行业经验，用心服务全球企业</div>
				<carousel class="carousel" :content="content"></carousel>
			</div>
		</div>
		<x-footer :title="'生态合作计划'" :describe="'招募合作伙伴，共创IoT生态'" :btn="'了解详情'"></x-footer>
	</div>
</template>
<script>
import SubTitle1 from '../components/SubTitle1.vue'
import SubTitle2 from '../components/SubTitle2.vue'
import RouterBox from '../components/RouterBox.vue'
import Detail from '../components/Detail.vue'
import Carousel from '../components/Carousel.vue'
import XFooter from '../components/xFooter.vue'
export default {
	components: {
		SubTitle1,
		SubTitle2,
		RouterBox,
		Detail,
		Carousel,
		XFooter,
	},
	data() {
		return {
			active: 1,
			/* 轮播图内容 */
			content: [
				{
					logo: require('../assets/img/sy_logo_welbilt.png'),
					title: 'Welbilt',
					describe: ['餐饮', '咖啡机物联服务'],
					content: 'Welbilt集团是全球餐饮设备行业的领导者,致力于为全球客户设计、制造和供应一流的餐饮设备。我们为其下咖啡机品牌CREM提供专业的咖啡机设备物联服务，助力其在共享商业领域的拓展。',
					img: require('../assets/img/sy_img_welbilt.png'),
				},
				{
					logo: require('../assets/img/sy_logo_lima.png'),
					title: 'LIMA立马',
					describe: ['交通', '电动车物联服务'],
					content: '立马是国内知名电动车行业领导者,专业从事新能源电动车研发、生产制造、应用推广于一体的大型集团化高新技术企业。我们为其提供专业的智能化电动车物联服务，助力其在智能化电动车的布局。',
					img: require('../assets/img/sy_img_lima.png'),
				},
				{
					logo: require('../assets/img/sy_logo_sofo.png'),
					title: 'SOFO索弗',
					describe: ['按摩椅', '按摩椅物联服务'],
					content: '索弗是一家集研发、实验、生产、行销和服务为—体的大型保健按摩器材的专业制造型集团企业。我们为其按摩椅提供专业的物联网服务,助力其在共享按摩椅的领域的领先。',
					img: require('../assets/img/sy_img_sofo.png'),
				},
			]
		}
	},
	methods: {
		ioTCardOver(index) {
			this.active = index;
		},
		ioTCardLeave() {
			this.active = 1;
		},
		push (url, name, extra=false) {
			if (window?.location?.pathname === url) {
				return;
			}
			const { push } = window?._czc || {};
			if (push && typeof push === 'function') {
				push(['_trackEvent', name, '首页', url]);
			}
			if (extra) {
				window.location.href = url;
			} else {
				this.$router.push(url);
			}
		}
	}
}
</script>
<style lang="less" scoped>
.index {
	min-width: 1263px;
}
.video-box {
	font-size: 0;
	video {
		height: 100vh;
		width: 100%;
		object-fit: cover;
		z-index: -1;
	}
}

.content {
	max-width: @max-width;
	width: 100%;
	min-width: @min-width;
	margin: 0 auto;
	height: 100%;
	.title-box {
		position: absolute;
		top: 283px;
		@media screen and (max-height: 600px) {
			top: 200px;
		}
		.index-title {
			text-align: left;
			margin-bottom: 40px;
			width: 499px;
		}
		.index-title-describe {
			width: 468px;
		}
	}
	.IoT {
		height: 580px;
		margin-top: 90px;
		width: 100%;
		text-align: left;
		.sub-content {
			display: flex;
			.IoT-left {
				margin-right: 1px;
				.IoT-card-box {
					height: 260px;
					width: 300px;
					display: flex;
					margin-top: 60px;
					flex-direction: column;
					justify-content: space-between;
					.IoT-card {
						display: flex;
						width: 300px;
						height: 60px;
						background: #ffffff;
						box-shadow: 3px 3px 10px 0px rgba(46, 107, 246, 0.14);
						border-radius: 6px;
						line-height: 60px;
						align-items: center;
						transition: 0.2s all linear;
						.IoT-card-icon {
							height: 40px;
							width: 40px;
							margin: 0 20px;
							text-align: center;
							line-height: 40px;
							background: #f3f3f4;
							border-radius: 4px;
							font-size: 24px;
							color: #777f90;
						}
						.icon {
							text-shadow: 2px 2px 4px rgba(119, 127, 144, 0.5);
						}
						.IoT-card-text {
							font-size: 14px;
							color: #777f90;
							letter-spacing: 2px;
						}
					}
				}
				.IoT-title-describe {
					margin-top: 40px;
				}
				.detail {
					margin-top: 78px;
				}
			}
		}
	}
	.router {
		margin-top: 90px;
		display: flex;
		text-align: left;
		.router-left {
			margin-right: 9px;
		}
		.router-box {
			display: flex;
			flex-wrap: wrap;
			margin-top: 61px;
			align-content: space-between;
			height: 173px;
			width: 521px;
			justify-content: space-between;
		}
		.detail {
			position: relative;
			top: 80px;
		}
		.router-title-describe {
			width: 480px;
			margin-top: 40px;
		}
	}
}
.visualization-box {
	height: 984px;
	width: 100%;
	background: url(../assets/img/sy_bg.jpg);
	background-size: cover;
	.visualization {
		max-width: @max-width;
		width: 100%;
		min-width: @min-width;
		height: 100%;

		margin: 0 auto;
		text-align: center;
		.vis-title {
			margin: 60px auto 0;
		}
		.vis-title-describe {
			font-size: 16px;
			margin-top: 40px;
		}
		.vis-detail {
			margin: 20px auto 0;
		}
		.vis-img {
			margin-top: 40px;
			display: flex;
			font-size: 0;
			justify-content: space-between;
			.img-left {
				margin-right: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.img-box {
					width: 476px;
					height: 330px;
					overflow: hidden;
					position: relative;
					.img-title {
						position: absolute;
						font-size: 24px;
						bottom: 40px;
						color: #ffffff;
						line-height: 31px;
						width: 100%;
					}
				}
				img {
					width: 476px;
					height: 330px;
					transition: 0.3s all linear;
					&:hover {
						transform: scale(1.05);
					}
				}
			}
			.img-right {
				width: 704px;
				height: 680px;
				overflow: hidden;
				position: relative;
				img {
					width: 704px;
					height: 680px;
					transition: 0.3s all linear;
					&:hover {
						transform: scale(1.05);
					}
				}
				.img-title {
					position: absolute;
					font-size: 24px;
					bottom: 40px;
					color: #ffffff;
					line-height: 31px;
					width: 100%;
				}
			}
		}
	}
}
.trust {
	height: 803px;
	.trust-title {
		margin-top: 60px;
	}
	.trust-title-describe {
		margin-top: 40px;
		font-size: 16px;
	}
	.carousel {
		margin-top: 40px;
	}
}
.active {
	transform: scale(1.13);
	:nth-child(1) {
		background: #eaf0fe !important;
		color: #2e6bf6 !important;
	}
	:nth-child(2) {
		color: #2e6bf6 !important;
	}
}
</style>